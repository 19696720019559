import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  topBar: {
    issuesHistory: 'Issues History',
    projectManagement: 'Project Management',
    typeToSearch: 'Search...',
    findPages: 'Find pages...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Hello',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Phone',
      editProfile: 'Edit Profile',
      logout: 'Logout',
    },
    panelManagement: {
      title: 'Control panel',
      main: 'Main',
      users: 'Users',
      others: 'Others',
      roles: 'Roles & Permissions',
      promocodes: 'Promocodes',
      analytics: 'Analytics',
      binancio: 'Binancio',
      settings: 'Settings',
      psystems: 'Pay systems',
      currencies: 'Currencies & Rates',
      projects: 'Projects',
    },
  },
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
