<template>
  <div>
    <a-form-model
      ref="addAccrualForm"
      :model="addAccrualForm"
      :rules="addAccrualRules"
    >
      <a-row>
        <a-col :span="12" class="pr-2">
          <a-form-model-item ref="amount" label="Сумма" prop="amount">
            <a-input-number
              v-model="addAccrualForm.amount"
              class="w-100"
              :default-value="1000"
              :step="0.01"
              :min="1"
              :max="100000"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-2">
          <a-form-model-item ref="accrual_at" label="Дата списания" prop="accrual_at">
            <a-date-picker
              v-model="addAccrualForm.accrual_at"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              type="date"
              placeholder="Выберите дату"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <hr/>
    <a-row class="mt-4">
      <a-col :span="8">
        <a-button @click="closeModal">
          Отмена
        </a-button>
      </a-col>
      <a-col :span="16" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          {{ accrualInfo ? 'Сохранить' : 'Добавить' }}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'addAccrual',
  props: ['accrualInfo', 'accountId'],
  computed: {
    // ---
  },
  data() {
    return {
      submitLoading: false,

      addAccrualForm: {
        amount: this.accrualInfo ? this.accrualInfo.amount : 1,
        accrual_at: this.accrualInfo ? this.accrualInfo.accrual_at : this.$moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      addAccrualRules: {
        amount: [{ required: true, message: 'Укажите сумму', trigger: 'blur' }],
        accrual_at: [{ required: true, message: 'Укажите дату', trigger: 'blur' }],
      },
    }
  },
  mounted () {
    //
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    onSubmit() {
      this.$refs.addAccrualForm.validate(valid => {
        if (valid) {
          this.accrualInfo ? this.editAccrualSubmit() : this.addAccrualSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addAccrualSubmit() {
      this.submitLoading = true
      const url = `/admin/accounts/${this.accountId}/accruals`
      return apiClient.post(url, this.addAccrualForm).then((response) => {
        this.$notification.success({
          message: 'Начислено агенту',
          description: `Сумма: ${response.data.data.amount} $`,
        })
        this.submitLoading = false
        this.submitModal(response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при начислении',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    editAccrualSubmit() {
      this.submitLoading = true
      const url = `/admin/accounts/accruals/${this.accrualInfo.id}`
      return apiClient.patch(url, this.addAccrualForm).then((response) => {
        this.$notification.success({
          message: 'Запись обновлена',
          description: `Сумма: ${response.data.data.amount} $`,
        })
        this.submitLoading = false
        this.submitModal(response.data.data, true)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при обновлении записи',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
  },
}
</script>

<style scoped>
</style>
