<template>
  <div>
    <h4 style="padding-bottom: 12px"><a-icon type="setting" /> Платежные системы</h4>
    <a-row>
      <a-col :span="24" class="pr-1">
        <a-card class="">
          <psystems-table />
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import psystemsTable from '@/views/psystems/psystems/psystemsTable.vue'

export default {
  name: 'psystems',
  components: {
    psystemsTable,
  },
  data() {
    return {
      psystems: [],
    }
  },
  mounted () {
    //
  },
  methods: {
    //
  },
}

</script>
<style>
  .card-h-560 {
    min-height: 560px;
    max-height: 560px;
  }
</style>
<style scoped>
  .editable-row-operations a {
    margin-right: 8px;
  }
</style>
