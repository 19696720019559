<template>
  <div class="accounts-content">
<!--    <a-row>-->
<!--      <a-col :span="24">-->
<!--        <h4><a-icon type="credit-card" /> Аккаунты платежных систем</h4>-->
<!--      </a-col>-->
<!--    </a-row>-->
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-5">
        <span class="h5 mb-0"><font-awesome-icon icon="user-tie" class="mt-1 mr-1 font-size-24" /> Агенты</span>
        <a-button type="primary" @click="showAccountModal">
          <a-icon type="plus" /> Добавить агента
        </a-button>
      </div>
      <a-table
        :columns="accountColumns"
        :dataSource="accountsData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="accountsLoading"
        @change="handleTableChange"
      >
        <span slot="account" slot-scope="account, record">
          <router-link
            tag="a"
            class="button"
            :to="{name: 'transactions', params: { accountId: record.id }}"
          >
            <a href="javascript:" class="font-weight-bolder">{{ record.name }}</a>
          </router-link>
          <div><span class="font-size-10 pr-2 text-gray-5">#{{ record.id }}</span></div>
          <div class="mt-1">
            <span v-if="record.contact">
            <a-tooltip :title="`Contact via Telegram: @${record.contact.telegram}`" placement="bottom">
              <span v-if="record.contact.telegram" class="font-size-18 mr-2">
              <a :href="`https://t.me/${record.contact.telegram.replace('@','')}`" target="_blank">
                <font-awesome-icon :icon="['fab', 'telegram']" class="text-primary" />
              </a>
            </span>
            </a-tooltip>
            <a-tooltip :title="`Contact via Email: ${record.contact.email}`" placement="bottom">
              <span v-if="record.contact.email" class="font-size-18 mr-2">
                <a :href="`mailto:${record.contact.email}`" target="_blank">
                  <a-icon type="mail" class="text-gray-6" />
                </a>
              </span>
            </a-tooltip>
          </span>
            <a-tooltip :title="`Всего ${record.transactions_count.total} счетов`" placement="bottom">
            <a-badge
              :count="record.transactions_count.total"
              :number-style="{
              backgroundColor: '#fff',
              color: '#999',
              boxShadow: '0 0 0 1px #d9d9d9 inset',
              marginLeft: '0px',
              marginTop: '-4px',
              fontSize: '10px'
            }"
            />
          </a-tooltip>
          <a-tooltip :title="`${record.transactions_count.pending} счетов ожидают оплаты`" placement="bottom">
            <a-badge v-if="record.transactions_count.pending > 0"
                     :count="record.transactions_count.pending"
                     :number-style="{
              backgroundColor: '#fff',
              color: '#fa8c16',
              boxShadow: '0 0 0 1px #ffd591 inset',
              marginLeft: '4px',
              marginTop: '-4px',
              fontSize: '10px'
            }"
            />
          </a-tooltip>
          <a-tooltip :title="`${record.transactions_count.paid} оплаченных счетов`" placement="bottom">
            <a-badge v-if="record.transactions_count.paid > 0"
                     :count="record.transactions_count.paid"
                     :number-style="{
              backgroundColor: '#fff',
              color: '#41b883',
              boxShadow: '0 0 0 1px #41b883 inset',
              marginLeft: '4px',
              marginTop: '-4px',
              fontSize: '10px'
            }"
            />
          </a-tooltip>
          </div>
          <a-tooltip v-if="record.is_coward" title="Трусливый" placement="bottom">
            <span class="float-right">🐤</span>
          </a-tooltip>
        </span>
        <span slot="psystems" slot-scope="psystems, record">
          <div v-if="psystems.length>0">
            <span v-for="ps in psystems" :key="ps.id" class="p-1">
              <a-tooltip :title="ps.name" placement="bottom">
                <img :src="ps.img_path" height="24" width="24" />
              </a-tooltip>
            </span>
          </div>
          <div class="mt-1 font-size-12 text-gray-6" v-if="record.currencies.length>0" >
            Валюта счетов:
            <a-tag class="ml-0 mr-1">{{ currencies.find((cur) => cur.id === record.currency_id).name }}</a-tag>
<!--            <span v-for="cr in record.currencies" :key="cr.id" >-->
<!--              <a-tag class="ml-0 mr-1">{{ cr.name }}</a-tag>-->
<!--            </span>-->
          </div>
        </span>
        <span slot="rotate" slot-scope="rotate, record">
          <span class="account-status-dot">
            <span v-if="rotate===1">
              <a-tooltip title="Агент в работе" placement="bottom">
                <a-badge status="success" />
              </a-tooltip>
            </span>
            <span v-else>
              <span v-if="record.stop_reason">
                <a-tooltip title="Остановлен администратором" placement="bottom" v-if="record.stop_reason === 1">
                  <a-badge status="error" />
                </a-tooltip>
                <a-tooltip title="Агент закончил работу" placement="bottom" v-if="record.stop_reason === 2">
                  <a-badge color="grey" />
                </a-tooltip>
                <a-tooltip title="Временно приостановлен" placement="bottom" v-if="record.stop_reason === 11">
                  <span>😴</span>
                </a-tooltip>
                <a-tooltip title="Временно приостановлен" placement="bottom" v-if="[3, 5, 7, 8, 9, 10, 11].includes(record.stop_reason)" >
                  <a-badge status="warning" />
                </a-tooltip>
                <a-tooltip title="Остановлен из-за блокировки" placement="bottom" v-if="record.stop_reason === 4" >
                  <a-badge color="black" />
                </a-tooltip>
              </span>
            </span>
          </span>
        </span>
<!--        <span slot="requisites" slot-scope="requisites">-->
<!--          <div v-if="requisites.length>0">-->
<!--            <span v-for="req in requisites" :key="req.id">-->
<!--              <a-tooltip :title="`${req.psystem.name}: *${req.address.substr(-4)}`" placement="bottom" >-->
<!--                <a-tag class="ml-0 mr-1">-->
<!--                  <img :src="req.psystem.img_path" width="14" height="14" />-->
<!--                  <span :class="req.is_active !== 1 ? `text-gray-5` : ``"> {{ req.max_amount }} {{ req.currency.abbr }}</span>-->
<!--                </a-tag>-->
<!--              </a-tooltip>-->
<!--            </span>-->
<!--          </div>-->
<!--        </span>-->
        <template v-slot:debt="debt, record">
          <span v-if="record.max_debt">
            <a-tooltip :title="`Лимит: ${record.max_debt_inc ? record.max_debt + '$ + ' + record.balance : record.max_debt}$\nДоступно: ${record.debt_f}$ из ${(record.max_debt_inc ? record.max_debt + record.balance : record.max_debt).toFixed(2)}$`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <a-progress class="font-size-10" type="circle"
                          :percent="(record.debt_f / (record.max_debt_inc ? record.max_debt + record.balance : record.max_debt)) * 100"
                          :status="`${getDebtProgressStatus((record.debt_f / (record.max_debt_inc ? record.max_debt + record.balance : record.max_debt)) * 100)}`"
                          :width="60" >
                <template slot="format">
                  <span v-if="record.max_debt_inc" class="d-block mb-1" style="margin-top: -10px;"><i class="fe fe-trending-up font-size-14" /></span>
                  <span v-if="1 === 0">{{ (record.max_debt_inc ? record.max_debt + record.balance : record.max_debt).toFixed(1) }}$</span>
                  <span>{{ record.debt }}$</span>
                </template>
              </a-progress>
            </a-tooltip>
          </span>
        </template>
        <template v-slot:balance="balance, record">
          <div><a href="javascript:" @click="showBillingModal(record)">{{ balance }}$</a></div>
          <div class="small mt-2 text-gray-5">
            <a-tooltip title="Время в ротации за месяц" placement="bottom" >
              <span><a-icon type="clock-circle" /> {{ record.rotation_time }}</span>
            </a-tooltip>
          </div>
        </template>
<!--        <span slot="bch_comm" slot-scope="bch_comm">-->
<!--          {{ bch_comm }}%-->
<!--        </span>-->
<!--        <template v-slot:min_usd="min_usd">-->
<!--          {{ min_usd }}$-->
<!--        </template>-->
<!--        <span slot="contact" slot-scope="contact">-->
<!--        </span>-->
        <span slot="operation" slot-scope="text, record">
          <div class="text-right">
            <span v-if="rotateLoading && rotateId===record.id"><a-icon type="loading" /></span>
            <span v-else>
              <span v-if="record.in_rotate === 0">
                <a v-if="record.is_banned === 0" href="javascript:" @click="changeRotation(record.id, 1)">
                  <a-tooltip title="В ротацию" placement="bottom">
                    <font-awesome-icon icon="play" class="text-success font-size-18" />
                  </a-tooltip>
                </a>
                <font-awesome-icon v-else icon="play" class="text-gray-4 font-size-18" />
              </span>
              <span v-else>
                <a href="javascript:" @click="changeRotation(record.id, 0)">
                  <a-tooltip title="Остановить" placement="bottom">
                    <font-awesome-icon icon="stop" class="text-danger font-size-18" />
                  </a-tooltip>
                </a>
              </span>
            </span>
            <span class="ml-3" v-if="1 === 0">
              <a-tooltip title="Расчеты" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18" @click="showBillingModal(record)">
                  <font-awesome-icon icon="donate" class="text-gray-6 font-size-18" />
                </a>
              </a-tooltip>
            </span>
            <span class="ml-3">
              <a-tooltip :title="`Реквизиты (${record.requisites_count})`" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18" @click="showRequisitesModal(record)">
                  <font-awesome-icon v-if="record.requisites_count > 0" icon="wallet" class="text-gray-6 font-size-18" />
                  <font-awesome-icon v-else icon="wallet" class="text-gray-5 font-size-18" />
                </a>
              </a-tooltip>
            </span>
            <span class="ml-3">
              <a-tooltip title="Настройки" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18" @click="showEditAccountModal(record)"><a-icon type="control" theme="filled" /></a>
              </a-tooltip>
            </span>
            <span class="ml-3">
              <a-tooltip title="Удалить" placement="bottom">
                <a-popconfirm
                  title="Удалить агента?"
                  @confirm="deleteAccount(record)"
                >
                  <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon class="text-danger" type="delete" theme="filled" /></a>
                </a-popconfirm>
              </a-tooltip>
            </span>
          </div>
        </span>
      </a-table>
      <a-modal v-model="newAccountModal"
               :destroyOnClose="true"
               :title="editAccountObj ? `Изменить агента ${editAccountObj.name}` : 'Добавить нового агента'"
               :footer="null"
               class="add-account-modal"
               width="720px"
      >
        <add-account-component
          :account-info="editAccountObj"
          @closeModalFunction="closeAccountModal"
          @submitModalFunction="accountModalSubmit" />
      </a-modal>
      <a-modal v-model="requisitesModal"
               :destroyOnClose="true"
               :footer="null"
               class="requisites-modal"
               width="980px"
      >
        <account-requisites-component
          :account-obj="requisitesObj"
          @closeModalFunction="closeAccountModal"
          @changeModalFunction="requisiteModalChange"
        />
      </a-modal>
      <a-modal v-model="billingModal"
               :destroyOnClose="true"
               :footer="null"
               :closable="false"
               class="billing-modal"
               width="780px"
      >
        <billing-component
          :account-obj="billingObj"
          @closeModalFunction="closeBillingModal"
          @changeModalFunction="billingModalChange"
        />
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apiClient from '@/services/axios'
// import psMix from '@/services/psMix.js'
import moment from 'moment-timezone'
import addAccountComponent from '@/views/accounts/addModal.vue'
import accountRequisitesComponent from '@/views/accounts/requisites/requisitesModal.vue'
import billingComponent from '@/views/accounts/billing/billingModal.vue'

const accountColumns = [
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   key: 'id',
  //   width: '5%',
  // },
  {
    title: 'Имя агента',
    dataIndex: 'name',
    key: 'name',
    width: '16%',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: 'Банки',
    dataIndex: 'psystems',
    key: 'psystems',
    width: '15%',
    scopedSlots: { customRender: 'psystems' },
  },
  {
    title: 'Статус',
    dataIndex: 'in_rotate',
    key: 'in_rotate',
    width: '10%',
    align: 'center',
    scopedSlots: { customRender: 'rotate' },
  },
  // {
  //   title: 'Лимиты',
  //   dataIndex: 'requisites',
  //   key: 'requisites',
  //   width: '10%',
  //   scopedSlots: { customRender: 'requisites' },
  // },
  {
    title: 'Долг',
    dataIndex: 'debt',
    key: 'debt',
    width: '10%',
    align: 'center',
    scopedSlots: { customRender: 'debt' },
  },
  {
    title: 'Баланс',
    dataIndex: 'balance',
    key: 'balance',
    width: '10%',
    scopedSlots: { customRender: 'balance' },
  },
  // {
  //   title: 'Мин. платеж',
  //   dataIndex: 'min_amount_usd',
  //   key: 'min_amount_usd',
  //   width: '8%',
  //   scopedSlots: { customRender: 'min_usd' },
  // },
  // {
  //   title: 'Маржа',
  //   dataIndex: 'bch_comm',
  //   key: 'bch_comm',
  //   width: '8%',
  //   scopedSlots: { customRender: 'bch_comm' },
  // },
  {
    title: 'Действие',
    dataIndex: 'operation',
    width: '18%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  // mixins: [psMix],
  components: {
    addAccountComponent,
    accountRequisitesComponent,
    billingComponent,
  },
  computed: {
    ...mapGetters(['currencies']),
  },
  data() {
    return {
      accountsData: [],
      accountsLoading: false,
      accountColumns,
      moment,
      rotateLoading: false,
      rotateId: undefined,
      restrictionsLoading: false,
      restrictionsId: undefined,
      newAccountModal: false,
      requisitesModal: false,
      billingModal: false,
      editAccountObj: undefined,
      requisitesObj: undefined,
      billingObj: undefined,
      pagination: { pageSize: 10 },
      searchField: 'name',
      searchValue: '',
    }
  },
  mounted () {
    // this.connectToChannel()
    // this.getAccounts()
    this.fetch()
    // this.getPS()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.accountsLoading = true
      const reqData = { results: 20, ...params }
      console.log(reqData)
      const url = '/admin/accounts'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.accountsLoading = false
        this.accountsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.accountsLoading = false
      })
    },
    getAccounts() {
      this.accountsLoading = true
      const url = '/admin/accounts'
      apiClient.get(url).then((response) => {
        this.accountsData = response.data.data
        this.accountsLoading = false
      }).catch(error => { console.log(error); this.accountsLoading = false })
    },
    connectToChannel() {
      const token = '2d7e5c6a9ed89a9f92709df187a16fb81vDxWIvk'
      window.Echo.channel(token).listenToAll((event, e) => {
        console.log(event, e)
        if (event === '.PaymentAccepted') {
          console.log('Агент принял заявку')
        }
        if (event === '.PaymentCanceled') {
          console.log('Заявка отменена')
        }
        if (event === '.ConfirmPaymentIncome') {
          console.log('Агент подтвердил платеж')
        }
        if (event === '.PromocodeActivated') {
          console.log('SUCCESS! Промокод успешно активирован')
        }
      })
      console.log('connected')
    },
    showAccountModal() {
      this.editAccountObj = null
      this.newAccountModal = true
    },
    showEditAccountModal(obj) {
      this.editAccountObj = obj
      this.newAccountModal = true
    },
    showRequisitesModal(obj) {
      this.requisitesObj = obj
      this.requisitesModal = true
    },
    showBillingModal(obj) {
      this.billingObj = obj
      this.billingModal = true
    },
    closeAccountModal() {
      this.newAccountModal = false
    },
    closeBillingModal(stats, accountId) {
      if (stats && stats?.balance && accountId) {
        const psIndex = this.accountsData.findIndex(obj => obj.id === accountId)
        this.accountsData[psIndex].balance = stats.balance
      }
      this.billingModal = false
    },
    closeRequisitesModal() {
      this.requisitesModal = false
    },
    accountModalSubmit(account, edit) {
      if (edit) {
        const psIndex = this.accountsData.findIndex(obj => obj.id === account.id)
        this.accountsData[psIndex] = account
        console.log(account)
      } else {
        this.accountsData.unshift(account)
      }
      this.newAccountModal = false
    },
    requisiteModalChange(requisitesCount, account) {
      const accIndex = this.accountsData.findIndex(obj => obj.id === account.id)
      this.accountsData[accIndex].requisites_count = requisitesCount
      // console.log(requisitesCount)
    },
    billingModalChange(changed, account) {
      //
    },
    psHandleChange(e) {
      const psIndex = this.psystems.findIndex(obj => obj.id === e)
      this.addAccountForm.webhook_url = this.psystems[psIndex].webhook_url
      this.addAccountPSCurrencies = this.psystems[psIndex].currencies.map(function(row) {
        return { value: row.id, label: row.name }
      })
      if (this.externalPsystemIDs.includes(e)) {
        this.addAccountForm.token = 'external'
        this.addAccountForm.secret = 'external'
        this.addAccountForm.op_secret = ''
        this.addAccountForm.login = 'external[' + this.addAccountForm.name + ']'
      }
    },
    handleCurrencyChange() {
      console.log(this.addAccountForm.currencies)
      console.log(this.addAccountPSCurrencies)
      const qqw = this.addAccountPSCurrencies
      this.addAccountCurrencies = this.addAccountForm.currencies.map(function(row) {
        const cIndex = qqw.findIndex(obj => obj.value === row)
        return { value: row, label: qqw[cIndex].label }
      })
      console.log('addAccountCurrencies')
      console.log(this.addAccountCurrencies)
    },
    deleteAccount(acc) {
      const url = '/admin/accounts/' + acc.id
      apiClient.delete(url).then((response) => {
        console.log(response.data)
        this.$notification.success({
          message: 'Аккаунт удален',
          description: acc.name,
        })
        const accIndex = this.accountsData.findIndex(obj => obj.id === acc.id)
        this.accountsData.splice(accIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при удалении аккаунта',
          description: error.message,
        })
      })
    },
    changeRotation(accountID, status) {
      this.rotateLoading = true
      this.rotateId = accountID
      const notiMsg = ['Выведен из ротации', 'Помещен в ротацию']
      const url = '/admin/accounts/' + accountID + '/rotation/' + status
      apiClient.post(url).then((response) => {
        console.log(response.data)
        this.$notification.success({
          message: notiMsg[status],
          description: 'Аккаунт "' + response.data.data.name + '"',
        })
        const accIndex = this.accountsData.findIndex(obj => obj.id === accountID)
        this.accountsData[accIndex].in_rotate = response.data.data.in_rotate
        this.accountsData[accIndex].stopped_by_owner = response.data.data.stopped_by_owner
        this.accountsData[accIndex].stop_reason = response.data.data.stop_reason
        this.rotateLoading = false
        // this.$router.push('/banners')
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Не удалось изменить статус аккаунта',
          description: error.message,
        })
        this.rotateLoading = false
      })
    },
    async getPS() {
      const url = '/admin/ps'
      return apiClient.get(url).then((response) => {
        this.psystems = response.data.data
      }).catch(error => { console.log(error) })
    },
    async getPSCurrencies(psID) {
      const url = '/admin/ps/' + psID + '/currencies'
      return apiClient.get(url).then((response) => {
        this.addAccountCurrencies = response.data.data
        console.log('getPSCurrencies', this.addAccountCurrencies)
      }).catch(error => { console.log(error) })
    },
    getDebtProgressStatus(percent) {
      if (percent > 80) { return 'success' }
      return (percent < 20) ? 'exception' : 'normal'
    },
  },
}
</script>
<style>
.account-status-dot .ant-badge-status-dot {
  width: 16px;
  height: 16px;
}
.ant-progress-text {
  font-size: 11px !important;
  font-weight: bolder;
}
</style>
<style scoped>
.ant-progress-text {
  font-size: 10px;
}
</style>
