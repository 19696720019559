<template>
  <div>
    <a-form-model
      ref="psystemForm"
      :model="psystemForm"
      :rules="psystemRules"
    >
      <a-row>
        <a-col :span="12" class="pr-1">
          <a-form-model-item ref="name" label="Название платежной системы" prop="name">
            <a-input v-model="psystemForm.name" placeholder="Сбербанк, ВТБ, ..." />
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-1">
          <a-form-model-item ref="sys_name" label="Системное имя (на латинице)" prop="sys_name">
            <a-input v-model="psystemForm.sys_name" placeholder="sberbank, alfa_bank, vtb, ..." />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12" class="pr-1">
          <a-form-model-item ref="currencies" label="Валюты" prop="currencies">
            <a-select
              mode="multiple"
              v-model="psystemForm.currencies"
              style="width: 100%"
              placeholder="Валюты"
              :options="currencies"
              @change="handleCurrenciesChange"
              class="mt-0"
              :loading="currenciesLoading"
              :disabled="currenciesLoading"
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-1 mt-0">
          <a-form-model-item ref="deeplink" label="Deeplink" prop="deeplink">
            <a-input v-model="psystemForm.deeplink" placeholder="" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row class="mt-2">
        <a-col :span="12" class="pr-1">
          <a-form-model-item ref="address_type_id" label="Тип адреса получателя" prop="address_type_id">
            <a-select v-model="psystemForm.address_type_id"
                      placeholder="Выберите тип адреса"
                      :options="addressTypes"
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-1">
          <a-form-model-item label="Логотип">
            <a-upload
              name="psystem-logo"
              action="#"
              list-type="picture"
              class="logo-media-uploader"
              accept=".png,.jpg,.jpeg"
              :preview-file="previewFile"
              :before-upload="beforeUpload"
              :defaultFileList="img_path !== null ? [{ uid: '1', name: img_path.substring(img_path.lastIndexOf('/') + 1),
              status: 'done', url: img_path, thumbUrl: img_path }] : []"
              @change="fileChange"
              :remove="fileRemove"
            >
              <div v-if="uploadVisible" style="">
                <a-button > <a-icon type="upload" /> Загрузить </a-button>
                <span class="font-size-12 text-gray-5" style="margin-top: 10px !important;"> png (макс. размер файла 2MB)</span>
              </div>
              <!--                    <a-button> <a-icon type="upload" /> Upload </a-button>-->
            </a-upload>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row class="mt-5">
      <a-col :span="14">
        <a-button @click="closeModal">
          Отмена
        </a-button>
      </a-col>
      <a-col :span="10" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          {{ psystemInfo ? 'Сохранить' : 'Добавить' }}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'psystemModal.vue',
  props: ['psystemInfo'],
  computed: {
    // ---
  },
  data() {
    return {
      submitLoading: false,
      currenciesLoading: false,
      currencies: [],
      addressTypes: [],
      img_path: this.psystemInfo ? this.psystemInfo.img_path : null,
      // ---
      previewSrc: undefined,
      iconFile: undefined,
      updateIcon: false,
      // eslint-disable-next-line camelcase
      uploadVisible: !this.img_path,
      deleteIcon: false,
      // ---
      psystemForm: {
        name: this.psystemInfo ? this.psystemInfo.name : '',
        sys_name: this.psystemInfo ? this.psystemInfo.sys_name : '',
        currencies: this.psystemInfo ? this.psystemInfo.currencies.map(e => e.id) : [],
        address_type_id: this.psystemInfo ? this.psystemInfo.address_type_id : undefined,
        auto_accept_payments: this.psystemInfo ? this.psystemInfo.auto_accept_payments : 0,
        deeplink: this.psystemInfo ? this.psystemInfo.deeplink : '',
        // img_path: this.psystemInfo ? this.psystemInfo.img_path : undefined,
      },
      psystemRules: {
        name: [{ required: true, message: 'Укажите название', trigger: 'change' }],
        currencies: [{ required: true, message: 'Выберите валюты', trigger: 'change' }],
        address_type_id: [{ required: true, message: 'Выберите тип адреса', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
    this.getCurrencies()
    this.getAdressTypes()
    if (this.img_path) { this.uploadVisible = false }
    console.log('img path', this.img_path)
    console.log('img path bool', Boolean(this.img_path))
    console.log('upload vis', this.uploadVisible)
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    onSubmit() {
      this.$refs.psystemForm.validate(valid => {
        if (valid) {
          this.psystemInfo ? this.editPsystemSubmit() : this.addPsystemSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.psystemForm.resetFields()
    },
    handleCurrenciesChange(e) {
      console.log(e)
    },
    async addPsystemSubmit() {
      this.submitLoading = true
      const url = '/admin/ps'
      return await apiClient.post(url, this.psystemForm).then((response) => {
        this.$notification.success({
          message: 'Создана новая платежная система',
          description: response.data.data.name,
        })
        if (this.updateIcon) {
          this.uploadIcon(response.data.data.id).then((resp) => {
            this.submitLoading = false
            this.submitModal(response.data.data)
          })
        } else {
          this.submitLoading = false
          this.submitModal(response.data.data)
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при создании платежной системы',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    async editPsystemSubmit() {
      this.submitLoading = true
      const url = '/admin/ps/' + this.psystemInfo.id
      return await apiClient.patch(url, this.psystemForm).then((response) => {
        this.$notification.success({
          message: 'Платежная система обновлена',
          description: response.data.data.name,
        })
        if (this.updateIcon) {
          this.uploadIcon(this.psystemInfo.id).then((resp) => {
            console.log('upload resp', resp)
            this.submitLoading = false
            this.submitModal(resp.data.data, true)
          })
        } else {
          this.submitLoading = false
          this.submitModal(response.data.data, true)
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при обновлении платежной системы',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    async uploadIcon(psId) {
      const formData = new FormData()
      const url = '/admin/ps'
      formData.append('icon', this.iconFile)
      return apiClient.post(`${url}/${psId}/icon`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        console.log(`icon uploaded for psystem ${psId}`)
        return response
      }).catch(error => { console.log(error) })
    },
    async getCurrencies() {
      this.currenciesLoading = true
      const url = '/admin/currencies?active'
      return apiClient.get(url).then((response) => {
        this.currencies = response.data.data.map(({ id, name }) => ({ value: id, label: name }))
        console.log('getCurrencies', this.currencies)
        this.currenciesLoading = false
      }).catch(error => { console.log(error); this.currenciesLoading = false })
    },
    async getAdressTypes() {
      const url = '/admin/address-types'
      return apiClient.get(url).then((response) => {
        this.addressTypes = response.data.data.map(({ id, name }) => ({ value: id, label: name }))
        console.log('address-types', this.addressTypes)
      }).catch(error => { console.log(error) })
    },
    async previewFile(file) {
      console.log('Your upload file:', file)
      const base64 = await this.toBase64(file)
      this.iconFile = file
      this.previewSrc = base64
      console.log('Your upload base64 file:', base64)
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('Размер файла должен быть менее 2MB!')
        return false
      }
      return true
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    fileChange(info) {
      this.iconFile = info.file.originFileObj
      if (info.fileList.length > 0) {
        this.updateIcon = true
        this.uploadVisible = false
      } else {
        this.updateIcon = false
        this.uploadVisible = true
      }
      console.log('file: ' + this.iconFile)
      console.log('previewSrc: ' + this.previewSrc)
      console.log('to update: ' + this.updateIcon)
      console.log('to delete: ' + this.deleteIcon)
      console.log('upload btn visible: ' + this.uploadVisible)
    },
    fileRemove(info) {
      this.iconFile = null
      this.previewSrc = null
      this.img_path = null
      this.deleteIcon = true
      this.uploadVisible = true
      console.log('deleteIcon = true')
    },
  },
}
</script>
<style lang="scss">
  .logo-media-uploader {
    .ant-upload {
      width: 100%;
      height: 12px;
      margin-top: 0px;
    }
    .ant-upload-list {
      margin-top: -4px;
      height: 28px;
      margin-bottom: 0px;
    }
  }
</style>
<style scoped>
</style>
