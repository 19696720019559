<template>
  <div>
    <a-form-model
      ref="currencyRateForm"
      :model="currencyRateForm"
      :rules="currencyRateRules"
    >
      <div class="import-type mb-3">
        <a-form-model-item ref="import_type" label="Метод изменения курса валюты" prop="import_type">
          <a-radio-group v-model="currencyRateForm.import_type" button-style="solid" class="w-100">
            <a-radio-button :value="'default'" class="w-50 text-center">
              Ручной
            </a-radio-button>
            <a-radio-button value="binance" class="w-50 text-center">
              Binance
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
      </div>
      <a-row>
        <a-col :span="12" class="pr-1">
          <a-form-model-item v-if="currencyRateForm.import_type !== 'default'" ref="rate_inc" label="Корректировка LanPay курса" prop="rate_inc">
            <a-input-number
              v-model="currencyRateForm.rate_inc"
              class="w-100"
              :default-value="1000"
              :step="0.01"
              :min="-100000"
              :max="100000"
            />
          </a-form-model-item>
          <a-form-model-item v-else ref="custom_rate" label="LanPay курс" prop="custom_rate">
            <a-input-number
              v-model="currencyRateForm.custom_rate"
              class="w-100"
              :default-value="1000"
              :step="0.01"
              :min="0"
              :max="999999"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-1">
          <a-form-model-item ref="rate_a_inc" label="Корректировка курса агенту" prop="rate_inc">
            <a-input-number
              v-model="currencyRateForm.rate_a_inc"
              class="w-100"
              :default-value="1000"
              :step="0.01"
              :min="-100000"
              :max="100000"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="currencyRateForm.import_type !== 'default'">
        <a-col :span="12" class="pr-1">
          <a-form-model-item ref="dbab" :label="`Сумма ${currency.name} на покупку валюты`" prop="dbab">
            <a-input-number
              v-model="currencyRateForm.dbab"
              class="w-100"
              :default-value="5000"
              :step="1"
              :min="0"
              :max="999999"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-1 pt-2 text-left">
          <a-button class="mt-4" @click="showRatePreview"><a-icon type="cloud-sync" /> Предпросмотр</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <div v-if="previewRate && currencyRateForm.import_type !== 'default'">
      <div v-if="previewRateLoading" class="width-100p text-center"><a-spin size="large" /></div>
      <div v-else class="rate-preview">
        <a-card>
          <a-row :gutter="16">
            <a-col :span="8">
              <a-statistic title="Lanpay" :value="previewData.rate" style="margin-right: 50px">
                <template #suffix>
                  <a-icon type="line-chart" class="text-gray-5" />
                </template>
              </a-statistic>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Агенты" :value="previewData.rate_a" style="margin-right: 50px">
                <template #suffix>
                  <a-icon type="team" class="text-gray-5" />
                </template>
              </a-statistic>
            </a-col>
            <a-col :span="8">
              <a-statistic title="Binance" :value="previewData.rate_o" class="demo-class">
                <template #suffix>
                  <span class="text-gray-5">B</span>
                </template>
              </a-statistic>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </div>
    <a-row class="mt-4">
      <a-col :span="14">
        <a-button @click="closeModal">
          Отмена
        </a-button>
      </a-col>
      <a-col :span="10" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          Сохранить
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'currencyRateModal.vue',
  props: ['currencyRateInfo', 'currency'],
  computed: {
    // ---
  },
  data() {
    return {
      submitLoading: false,
      previewRate: undefined,
      previewRateLoading: false,
      previewData: {
        rate: 0,
        rate_a: 0,
        rate_o: 0,
      },
      currencyRateForm: {
        custom_rate: this.currencyRateInfo ? (this.currencyRateInfo.custom_rate ? this.currencyRateInfo.custom_rate : this.currencyRateInfo.rate) : 0,
        rate_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_inc : 0,
        rate_a_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_a_inc : 0,
        import_type: this.currency.imp_r_type ? this.currency.imp_r_type : 'default',
        dbab: this.currency.dbab ? this.currency.dbab : 5000,
      },
      currencyRateRules: {
        // name: [{ required: true, message: 'Укажите ISO код', trigger: 'change' }],
        // abbr: [{ required: true, message: 'Укажите символ валюты', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record) {
      this.$emit('submitModalFunction', record)
    },
    showRatePreview() {
      this.getBinanceRate()
      this.previewRate = true
    },
    onSubmit() {
      this.$refs.currencyRateForm.validate(valid => {
        if (valid) {
          this.editRateSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.currencyRateForm.resetFields()
    },
    async editRateSubmit() {
      this.submitLoading = true
      return ApiService.updateCurrencyRate(this.currencyRateInfo.id, this.currencyRateForm).then((response) => {
        this.$notification.success({
          message: 'Курс валюты обновлен',
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка обновления курса валюты',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    async getBinanceRate() {
      this.previewRateLoading = true
      return ApiService.getBinanceRate(this.currency.name, this.currencyRateForm.dbab).then((response) => {
        this.previewData.rate_o = parseFloat(response.data.data)
        this.previewData.rate = this.previewData.rate_o + this.currencyRateForm.rate_inc
        this.previewData.rate_a = this.previewData.rate_o + this.currencyRateForm.rate_a_inc
        this.previewRateLoading = false
      }).catch(error => {
        this.$notification.error({
          message: 'Не удалось получить курс с Binance',
          description: error.message,
        })
        this.previewRateLoading = false
      })
    },
  },
}
</script>
<style lang="scss">
</style>
<style scoped>
</style>
