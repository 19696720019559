<template>
  <div class="bills-content">
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <span class="h5 mb-0"><font-awesome-icon icon="money-check" class="mt-1 mr-1 font-size-24" /> Заявки</span>
      </div>
      <div class="d-flex mb-3">
        <div>
          <a-icon type="api" />
          :
          <a-select v-model="projectId" placeholder="Проект" class="width-150" @change="handleProjectChange">
            <a-select-option :value="0" >
              [Все проекты]
            </a-select-option>
            <a-select-option v-for="project in activeProjectList" :key="project.id" >
              <a-badge status="success" v-if="project.is_active" />
              <a-badge color="grey" v-else />
              {{ project.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-icon type="search" class="mr-1" />:
          <a-select v-model="searchField" class="mr-2 width-150" @change="handleSearchChanged">
            <a-select-option value="id">
              Номер заявки
            </a-select-option>
            <a-select-option value="login">
              Логин
            </a-select-option>
          </a-select>
          <a-input-search
            v-model="searchValue"
            :placeholder="searchField === 'id' ? 'Укажите номер заявки' : 'Укажите логин клиента'"
            style="width: 240px"
            allowClear
            @search="searchByInput"
          >
          </a-input-search>
        </div>
        <div class="ml-auto">
          <a-button :loading="paymentsTableLoading" @click="refreshList"><a-icon type="reload" /> Обновить</a-button>
        </div>
      </div>
      <a-table
        :columns="paymentsColumns"
        :dataSource="paymentsData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="paymentsTableLoading"
        @change="handleTableChange"
      >
        <template v-slot:id="id">
          <span>{{ id }}</span>
        </template>
        <template v-slot:status="status, record">
<!--          {{ status }}-->
          <a-tooltip :title="`(${record.chat.msg_count}) сообщений в чате`" placement="bottom" v-if="record.chat && record.chat.msg_count">
<!--            <i class="fe fe-message-circle font-size-18 text-primary" />-->
            <a href="javascript:" @click="openChatModal(record.chat)" class="mr-2"><a-icon type="wechat" class="text-primary font-size-18" /><sup class="">{{ record.chat.msg_count }}</sup></a>
          </a-tooltip>
          <a-tooltip title="Ожидает оплаты" placement="bottom">
            <span v-if="status === 'ACCEPTED'" class="font-size-18">
              <font-awesome-icon icon="money-check" class="text-gray-4" />
            </span>
          </a-tooltip>
          <a-tooltip :title="(record.card)? 'Оплачен \n' + record.card.brand + ': ******' + record.card.last4 : 'Оплачен'" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
            <span v-if="status && ['CONFIRMED', 'ACTIVATED', 'COMPLETED'].includes(status)" class="font-size-18" >
              <font-awesome-icon icon="money-check" class="text-success" />
            </span>
          </a-tooltip>
          <a-tooltip title="Отменен" placement="bottom">
            <span v-if="status === 'CANCELED'" class="font-size-18 mr-1">
              <font-awesome-icon icon="times-circle" class="text-danger" />
            </span>
          </a-tooltip>
          <a-tooltip title="Просрочен" placement="bottom">
            <span v-if="status === 'EXPIRED'" class="font-size-18">
              <font-awesome-icon icon="money-check" class="text-danger" />
            </span>
          </a-tooltip>
          <a-tooltip title="Подтвержден клиентом" placement="bottom">
            <span v-if="status === 'CASHED'" class="font-size-18">
              <font-awesome-icon icon="money-check" class="text-gray-6" />
            </span>
          </a-tooltip>
          <a-tooltip title="Требуется скриншот" placement="bottom">
            <span v-if="status === 'REJECTED'" class="font-size-18">
              <font-awesome-icon icon="money-check" class="text-warning" />
            </span>
          </a-tooltip>
          <a-tooltip title="Не подтвержден агентом" placement="bottom">
            <span v-if="status === 'DECLINED'" class="font-size-18">
              <font-awesome-icon icon="money-check" class="text-warning" />
            </span>
          </a-tooltip>
          <span v-if="1 === 0">
            <a-tooltip v-if="record.transferred" title="Выведен" placement="bottom">
              <span class="font-size-18 ml-2">
                <font-awesome-icon icon="external-link-square-alt" class="text-success" />
              </span>
            </a-tooltip>
            <a-tooltip v-else title="Не выведен" placement="bottom">
              <span class="font-size-18 ml-2">
                <font-awesome-icon icon="external-link-square-alt" class="text-gray-4" />
              </span>
            </a-tooltip>
          </span>
          <span v-if="paymentsLoading" class="ml-2"><a-icon type="loading" /></span>
        </template>
        <template v-slot:account="account">
          <span v-if="account">{{ account.name }}</span>
        </template>
        <template v-slot:requisites="requisites, record">
          <span v-if="record.psystem"><img :src="record.psystem.img_path" width="18" height="18" /> {{ record.psystem.name }}</span>
          <span v-if="requisites" class="d-block ml-4 font-size-12 text-gray-5">{{ requisites.address }}</span>
        </template>
        <template v-slot:created_at="created_at">
          {{ $moment(created_at).format('DD.MM.YY') }}
          <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment(created_at).format('HH:mm') }}</span>
        </template>
<!--        <span slot="bill_id" slot-scope="bill_id, record">-->
<!--          <a :href="record.bill_url" target="_blank">{{ bill_id }}</a>-->
<!--        </span>-->
        <template v-slot:promocode="promocode, record">
          <span v-if="promocode">
            <a-icon type="loading" class="promocode-loading" :id="`promoLoading${promocode.id}`" />
            <a-tooltip title="Активирован" placement="bottom">
              <a-icon v-if="promocode.is_activated" type="check"  class="text-success" />
            </a-tooltip>
            <span v-if="promocode.is_activated === 0">
              <a-popconfirm
                :title="`Активировать промокод для заявки #${record.id}?`"
                @confirm="activatePromocode(promocode.id)"
              >
                <a-tooltip title="Активировать" placement="bottom" class="mr-1">
                  <a href="javascript:"><a-icon type="shake"  class="text-warning" /></a>
                </a-tooltip>
              </a-popconfirm>
            </span>
            {{ promocode.code }}
          </span>
        </template>
        <template v-slot:client="client">
          {{ client.login }}
          <span v-if="client.short_name" class="d-block text-gray-5">
            {{ client.short_name }}
          </span>
        </template>
        <template v-slot:amount="amount, record">
          <span>
            {{ amount }} {{ record.currency.abbr }}
          </span>
          <span v-if="record.transaction && record.transaction.status === 2" class="pr-1">
            <a-icon type="arrow-down" class="text-success" />
          </span>
          <span v-if="record.client_report">
            <a-tooltip title="Скриншот от клиента" placement="bottom">
              <a :href="record.client_report.file_path" target="_blank">
<!--                <a-icon type="file-image" class="text-primary" />-->
                <font-awesome-icon icon="file-image" class="text-primary" />
              </a>
            </a-tooltip>
          </span>
        </template>
        <template v-slot:invoice="invoice">
          <span v-if="invoice">
            <span>
              <a-tooltip title="Оплачен агентом" placement="bottom" v-if="invoice.status === 2">
                <font-awesome-icon icon="money-check-alt" class="font-size-16 text-success" />
              </a-tooltip>
              <a-tooltip title="Ожидает оплаты агентом" placement="bottom" v-else>
                <font-awesome-icon icon="money-check-alt" class="font-size-16 text-gray-4" />
              </a-tooltip>
            </span>
             {{ invoice.tx_id }}
            <span v-if="invoice.m_id" class="d-block mt-1 font-size-12 text-gray-5">
<!--              <a-avatar :size="16" class="text-white" :style="{backgroundColor: '#1890ff', marginTop: '-4px'}">M</a-avatar>-->
              <font-awesome-icon icon="layer-group" class="text-gray-4 font-size-14" />
              <span class="mt-1 ml-1">M{{ invoice.m_id }}</span>
            </span>
<!--            <span v-if="record.agent_invoice" class="mr-1 font-size-18">-->
<!--            <span v-if="record.agent_invoice.status === 2">-->
<!--              <a-tooltip title="Оплачен агентом" placement="bottom">-->
<!--                <font-awesome-icon icon="donate" class="text-success" />-->
<!--              </a-tooltip>-->
<!--            </span>-->
<!--            <span v-else>-->
<!--              <a-tooltip title="Ожидает оплаты агентом" placement="bottom">-->
<!--                <font-awesome-icon icon="donate" class="text-gray-4" />-->
<!--              </a-tooltip>-->
<!--            </span>-->
<!--          </span>-->
          </span>
        </template>
      </a-table>
    </a-card>
    <a-modal v-model="paymentChatModal"
             :destroyOnClose="true"
             title="Клиент-Агент чат"
             :footer="null"
             class="chat-modal"
             width="680px"
             height="680px"
    >
      <agent-chat-modal
        :chat-obj="paymentChat"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import apiClient from '@/services/axios'
import moment from 'moment-timezone'
import accessMix from '@/services/accessmix.js'
import agentChatModal from '@/components/client-agent-chat/AgentChatModal.vue'

const paymentsColumns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Создана',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Агент',
    dataIndex: 'account',
    key: 'account',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: 'Реквизиты',
    dataIndex: 'requisites',
    key: 'requisites',
    width: '15%',
    scopedSlots: { customRender: 'requisites' },
  },
  {
    title: 'Промокод',
    dataIndex: 'promocode',
    key: 'promocode',
    scopedSlots: { customRender: 'promocode' },
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    key: 'amount',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Счет',
    dataIndex: 'agent_invoice',
    key: 'agent_invoice',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'invoice' },
  },
  // {
  //   title: 'Комиссия',
  //   dataIndex: 'сomm',
  //   key: 'сomm',
  //   // scopedSlots: { customRender: 'сomm' },
  // },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: '10%',
    align: 'right',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'index',
  mixins: [accessMix],
  components: {
    agentChatModal,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['activeProjectList']),
  },
  data() {
    return {
      paymentsData: [],
      paymentsColumns,
      moment,
      paymentsLoading: false,
      paymentsTableLoading: false,
      pagination: { pageSize: 20 },
      searchField: 'id',
      searchValue: '',
      paymentChatModal: false,
      paymentChat: null,
      projectId: 0,
    }
  },
  mounted () {
    if (this.userHasRole('analytics')) {
      this.$router.push('/analytics')
    }
    this.refreshList()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        // sortField: sorter.field,
        // sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
        project_id: this.projectId,
      })
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.paymentsTableLoading = true
      const reqData = { results: 20, ...params }
      console.log(reqData)
      const url = '/admin/payments?accepted'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.paymentsTableLoading = false
        this.paymentsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.paymentsTableLoading = false
      })
    },
    refreshList() {
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        searchField: this.searchField,
        searchValue: this.searchValue,
        project_id: this.projectId,
      })
      // console.log(this.pagination)
    },
    handleSearchChanged() {
      this.searchValue = ''
    },
    handleProjectChange(e) {
      this.refreshList()
    },
    async searchByInput(value) {
      // if (value === '') { this.searchValue =  }
      this.pagination.current = 1
      this.refreshList()
    },
    async activatePromocode(promoId) {
      const pLoading = document.getElementById(`promoLoading${promoId}`)
      pLoading.style.display = 'inline-block'
      const url = `/admin/promocodes/${promoId}/activate`
      return await apiClient.post(url).then((response) => {
        this.$notification.success({
          message: 'Промокод активирован',
          description: `Заявка #${response.data.data.id}, клиент ${response.data.data.client.login}`,
        })
        const tData = response.data.data
        // console.log('response', tData)
        const index = this.paymentsData.findIndex(obj => obj.id === tData.id)
        this.paymentsData[index] = tData
        pLoading.style.display = 'none'
        this.refreshList()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка активации промокода',
          description: error.message,
        })
        pLoading.style.display = 'none'
      })
    },
    openChatModal(chatObj) {
      this.paymentChat = chatObj
      this.paymentChatModal = true
    },
  },
}
</script>
<style>
  .promocode-loading { display: none; }
</style>
<style scoped>

</style>
