<template>
  <div class="bills-content">
<!--    <a-row>-->
<!--      <a-col :span="16">-->
<!--        <h4 class="text-primary"><font-awesome-icon icon="file-invoice-dollar" /> Счета</h4>-->
<!--      </a-col>-->
<!--      <a-col :span="8" class="text-right">-->
<!--        <a-button :loading="transactionsLoading" @click="getTransactions"><a-icon type="reload" /> Обновить</a-button>-->
<!--      </a-col>-->
<!--    </a-row>-->
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <span class="h5 mb-0"><font-awesome-icon icon="money-check-alt" class="mt-1 mr-1 font-size-24" /> Crypto-Счета</span>
      </div>
      <div class="d-flex mb-3">
        <div>
          <a-icon type="search" class="mr-1" />
          <a-input-search
            v-model="searchValue"
            placeholder="Номер счета (TX_ID)"
            style="width: 180px"
            @search="searchById"
            allowClear
          >
          </a-input-search>
        </div>
        <div class="ml-3">
          <a-icon type="user" />
          Агент:
          <a-select v-model="accountId" placeholder="Выберите агента" class="width-200">
            <a-select-option :value="0" >
              Все агенты
            </a-select-option>
            <a-select-option v-for="account in accountsData" :key="account.id" >
              {{ account.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          Статус:
          <a-select v-model="status" placeholder="Выберите статус платежей" class="width-150">
            <a-select-option value="all" >Любой</a-select-option>
            <a-select-option value="unpaid" ><a-icon type="exclamation" class="text-danger" /> Неоплаченные</a-select-option>
            <a-select-option value="paid" ><a-icon type="check" class="text-success" /> Оплаченные</a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-button @click="handleAgentChange">Применить</a-button>
        </div>
        <div class="ml-auto">
<!--          <a-button type="primary"><a-icon type="file-excel" /> Экспорт</a-button>-->
          <a-button :loading="invoiceTableLoading" @click="refreshList"><a-icon type="reload" /> Обновить</a-button>
        </div>
      </div>
      <a-table
        :columns="invoiceColumns"
        :dataSource="invoiceData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="invoiceTableLoading"
        @change="handleTableChange"
      >
        <span slot="status" slot-scope="status, record">
          <span v-if="invoiceLoading" class="ml-2"><a-icon type="loading" /></span>
          <a-tooltip v-if="status === 2" :title="`💰 Оплачен\n${$moment(record.paid_at).format('DD.MM.YY HH:mm:ss')}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
            <span class="font-size-18" >
              <font-awesome-icon icon="money-check" class="text-success" />
            </span>
          </a-tooltip>
          <span v-else>
            <span v-if="(status === 1 || status === 4) && !record.m_id">
              <a-tooltip  title="Принять платеж" placement="bottom">
                <a-popconfirm
                  title="Средства могли не поступить в полном объеме. Принять платеж в любом случае?"
                  @confirm="acceptInvoice(record.id)"
                >
                  <span class="font-size-18 mr-2">
                    <a href="javascript:"><font-awesome-icon icon="check-circle" class="text-gray-6" /></a>
                  </span>
                </a-popconfirm>
              </a-tooltip>
            </span>
            <span v-if="record.is_master" class="mr-3">
              <a-tooltip title="Мастер-счет" placement="bottom">
                <a-badge :count="record.children_count" :number-style="{
                  backgroundColor: '#fff',
                  color: '#999',
                  boxShadow: '0 0 0 1px #d9d9d9 inset',
                  marginTop: '-6px'
                }">
<!--                  <a-avatar :size="22" class="text-white" :style="{backgroundColor: '#1890ff'}">M</a-avatar>-->
                  <font-awesome-icon icon="layer-group" class="text-primary font-size-18" />
                </a-badge>
              </a-tooltip>
              <span class="ml-3">
                <a-tooltip :title="`Разгруппировать`" placement="bottom">
                  <a-popconfirm
                    title="Разгруппировать мастер-счет?"
                    @confirm="deleteMaster(record.account.id)"
                  >
                    <a href="javascript:;"><font-awesome-icon icon="object-ungroup" class="mt-1 font-size-18 text-gray-5" /></a>
                  </a-popconfirm>
                </a-tooltip>
              </span>
            </span>
            <span v-else>
              <span v-if="!record.m_id">
<!--                <span v-if="status === 1">-->
<!--                  <a-tooltip  title="Принять платеж" placement="bottom">-->
<!--                    <a-popconfirm-->
<!--                      title="Средства могли не поступить в полном объеме. Принять платеж в любом случае?"-->
<!--                      @confirm="acceptInvoice(record.id)"-->
<!--                    >-->
<!--                      <span class="font-size-18 mr-2">-->
<!--                        <a href="javascript:"><font-awesome-icon icon="check-circle" class="text-gray-6" /></a>-->
<!--                      </span>-->
<!--                    </a-popconfirm>-->
<!--                  </a-tooltip>-->
<!--                </span>-->
                <span v-if="(status === 0 && record.tmp) || (status === 4)">
                  <a-tooltip title="Удалить. Списать долг" placement="bottom">
                    <a-popconfirm
                      title="Списать долг?"
                      @confirm="deleteInvoice(record.id)"
                    >
                      <span class="font-size-18 mr-2">
                        <a href="javascript:"><font-awesome-icon icon="minus-circle" class="text-danger" /></a>
                      </span>
                    </a-popconfirm>
                  </a-tooltip>
                </span>
              </span>
            </span>
            <span v-if="status === 0 && !record.tmp">
              <a-tooltip v-if="record.err_cnt > 0" title="❗Адрес оплаты не сформирован, ошибка" placement="bottom">
                <span class="font-size-18">
                  ❗<font-awesome-icon icon="money-check" class="text-gray-4" />
                </span>
              </a-tooltip>
              <a-tooltip v-else title="В ожидании адреса оплаты" placement="bottom">
                <span class="font-size-18">
                  <font-awesome-icon icon="money-check" class="text-gray-4" />
                </span>
              </a-tooltip>
            </span>
            <a-tooltip v-if="status === 0 && record.tmp && record.to_del" :title="`⏱ Временный\nБудет удален при простое`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span class="font-size-18 pr-1">
                <font-awesome-icon icon="clock" class="text-gray-4" />
              </span>
            </a-tooltip>
            <a-tooltip v-if="status === 0 && record.tmp && !record.to_del" :title="`👮 Временный\n На арбитраже`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4" />
              </span>
            </a-tooltip>
            <a-tooltip v-if="status === 1" title="Ожидает оплаты" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-warning" />
              </span>
            </a-tooltip>
            <a-tooltip v-if="status === 3" title="Отменен" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-danger" />
              </span>
            </a-tooltip>
            <a-tooltip v-if="status === 4" :title="`Просрочен\n${$moment(record.expires_at).format('DD.MM.YY HH:mm:ss')}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-danger" />
              </span>
            </a-tooltip>
          </span>
        </span>
        <template v-slot:created_at="created_at">
          {{ $moment(created_at).format('DD.MM.YY') }}
          <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment(created_at).format('HH:mm') }}</span>
        </template>
        <template v-slot:tx_id="tx_id, record">
          <span v-if="tx_id">
            <span v-if="record.is_master" class="font-weight-bold">
              {{ tx_id }}
            </span>
            <span v-else >
              {{ tx_id }}
            </span>
            <a-tooltip title="Скопировать" placement="right" class="ml-0">
              <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(tx_id)" /></a>
            </a-tooltip>
          </span>
          <span v-if="record.m_id" class="d-block mt-1">
            <a-tooltip title="Принадлежит мастер-счету" placement="bottom">
              <span class="font-size-12 text-gray-5 ml-1"><font-awesome-icon icon="layer-group" class="" /> M{{ record.m_id }}</span>
            </a-tooltip>
          </span>
        </template>
        <template v-slot:amount="amount, record">
          <span v-if="amount">
            {{ amount }} <span v-if="record.currency.abbr">{{ record.currency.abbr }}</span>
          </span>
        </template>
        <template v-slot:amount_crypto="amount_crypto, record">
          <span v-if="amount_crypto">
            {{ Number(amount_crypto).toFixed(4) }}
            <span v-if="record.currency_crypto" class="font-size-12 text-gray-5">
              {{ record.currency_crypto.name }}
            </span>
          </span>
        </template>
        <template v-slot:transaction="transaction, record">
          <span v-if="record.is_master">
            {{ record.p_ids.join(', ') }}
          </span>
          <span v-else>
            <span v-if="transaction">
              <a-tag>{{ transaction.trec_id }}</a-tag>
            </span>
          </span>

        </template>
        <span slot="account" slot-scope="account, record">
          <span v-if="account">
            <span>
              <span v-if="record.account.psystem_id === 1"><img src="/resources/images/ps/qiwi-logo.png" height="16" /></span>
              <span v-if="record.account.psystem_id === 2"><img src="/resources/images/ps/ik-logo.png" height="16" /></span>
              <span v-if="record.account.psystem_id === 3"><img src="/resources/images/ps/stripe-logo.png" height="16" /></span>
            </span>
            {{ record.account.name }}
            <a-tooltip v-if="record.account.is_coward" title="Трусливый" placement="bottom">
              <span class="float-right">🐤</span>
            </a-tooltip>
          </span>
        </span>
        <span slot="login" slot-scope="login, record">
          <span v-if="login">{{ record.transaction.login }}</span>
        </span>
        <span slot="address" slot-scope="address, record">
          <span v-if="address">
<!--            <a-badge v-if="record.address" color="green" text="" />-->
            ...{{ address.slice(-6) }}
            <a-tooltip title="Скопировать" placement="right">
              <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(address)" /></a>
            </a-tooltip>
          </span>
          <span v-if="record.address_legacy" class="d-block">
            <a-badge color="orange" text="" />
            ...{{ record.address_legacy.slice(-8) }}
            <a-tooltip title="Скопировать" placement="right">
              <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(record.address_legacy)" /></a>
            </a-tooltip>
          </span>
          <span v-if="!address && !record.address_legacy">
            <a-tooltip title="Адреса отсутствуют" placement="bottom">
              <a-icon  type="warning"  class="text-warning" />
            </a-tooltip>
          </span>
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import moment from 'moment-timezone'

const invoiceColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Выставлен',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'TX_ID',
    dataIndex: 'tx_id',
    key: 'tx_id',
    width: '12%',
    scopedSlots: { customRender: 'tx_id' },
  },
  {
    title: 'Агент',
    dataIndex: 'account',
    key: 'account',
    width: '12%',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: 'Заявка',
    dataIndex: 'transaction',
    key: 'transaction',
    width: '8%',
    scopedSlots: { customRender: 'transaction' },
  },
  {
    title: 'Логин',
    dataIndex: 'transaction.login',
    key: 'transaction.login',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'USD',
    dataIndex: 'amount',
    key: 'amount',
    width: '10%',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Crypto',
    dataIndex: 'amount_crypto',
    key: 'amount_crypto',
    width: '12%',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount_crypto' },
  },
  {
    title: 'Кошелек',
    dataIndex: 'address',
    key: 'address',
    // sorter: (a, b) => a.income - b.income,
    width: '15%',
    scopedSlots: { customRender: 'address' },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: '20%',
    align: 'right',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'index',
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      invoiceData: [],
      accountsData: [],
      invoiceColumns,
      moment,
      invoiceLoading: false,
      invoiceTableLoading: false,
      pagination: { pageSize: 20 },
      searchField: 'tx_id',
      searchValue: '',
      accountId: 0,
      status: 'all',
    }
  },
  mounted () {
    // this.getTransactions()
    this.getAccounts().then(() => { this.fetch() })
    // this.fetch()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
        account_id: this.accountId,
        status: this.status,
      })
    },
    handleAgentChange() {
      console.log('agent changed')
      this.searchValue = ''
      this.pagination.current = 1
      this.refreshList()
    },
    async fetch(params = {}) {
      console.log('params:', params)
      this.invoiceTableLoading = true
      const reqData = { results: 20, ...params }
      console.log(reqData)
      const url = '/admin/invoices'
      return await apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.invoiceTableLoading = false
        this.invoiceData = response.data.data
        this.pagination = pagination
        console.log(this.invoiceData)
      }).catch(error => {
        console.log(error)
        this.invoiceTableLoading = false
      })
    },
    refreshList() {
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        account_id: this.accountId,
        status: this.status,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    async searchById(value) {
      this.accountId = 0
      this.status = 'all'
      this.searchField = 'tx_id'
      this.pagination.current = 1
      this.refreshList()
    },
    async acceptInvoice(invoiceId) {
      const url = `/admin/invoices/${invoiceId}/accept`
      return await apiClient.patch(url).then((response) => {
        // console.log(response.data.data)
        this.$notification.success({
          message: 'Принят платеж по счету',
          description: `Счет #${response.data.data.tx_id}`,
        })
        this.refreshList()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Не удалось принять платеж',
          description: error.message,
        })
      })
    },
    async deleteInvoice(invoiceId) {
      const url = `/admin/invoices/${invoiceId}`
      return await apiClient.delete(url).then((response) => {
        console.log(response.data.data)
        const index = this.invoiceData.findIndex(obj => obj.id === invoiceId)
        this.invoiceData.splice(index, 1)
        this.$notification.success({
          message: 'Крипто-счет удален',
          description: '',
        })
        // this.refreshList()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Не удалось удалить крипто-счет',
          description: error.message,
        })
      })
    },
    async getAccounts() {
      const url = '/admin/accounts?short'
      return apiClient.get(url).then((response) => {
        this.accountsData = response.data.data
        console.log('accounts', this.accountsData)
      }).catch(error => { console.log(error) })
    },
    async deleteMaster(accountId) {
      const url = '/admin/invoices/master/' + accountId
      return apiClient.delete(url).then((response) => {
        this.refreshList()
        console.log('master invoice deleted for account ' + accountId)
      }).catch(error => { console.log(error) })
    },
    clipboardHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.$notification.success({
        message: 'Скопировано в буфер обмена',
        description: `${v.slice(0, 16)}...`,
      })
    },
  },
}
</script>

<style scoped>

</style>
