import apiClient from '../axios'
// import chatHeader from './chat-header'

class ApiService {
  getCurrencies(active = false) {
    let query = '?no_crypto'
    query = active ? query + '&active' : query
    return apiClient
      .get(`/admin/currencies${query}`)
      .then((response) => {
        return response.data.data
      })
  }

  getPsystems(active = false, currencyId = false) {
    const act = active ? 'active' : ''
    const cur = currencyId ? `currency_id=${currencyId}` : ''
    let query = act.length > 0 ? act + '&' : ''
    query += cur
    return apiClient
      .get(`/admin/ps?${query}`)
      .then((response) => {
        return response.data.data
      })
  }

  updatePsystemSort(sortData) {
    return apiClient
      .patch('/admin/ps/update_sort', sortData)
      .then((response) => {
        return response.data.data
      })
  }

  getProjects(activeOnly = false, short = false) {
    const query = `active=${activeOnly}&short=${short}`
    return apiClient
      .get(`/admin/projects?${query}`)
      .then((response) => {
        return response.data.data
      })
  }

  getAccountRequisites(accountId, params = []) {
    return apiClient
      .get(`/admin/accounts/${accountId}/requisites`, { params: params })
      .then((response) => {
        return response.data
      })
  }

  changeRequisiteActive(requisiteId, active) {
    return apiClient
      .get(`/admin/accounts/requisites/${requisiteId}/active/${active}`)
      .then((response) => {
        return response.data.data
      })
  }

  setRequisiteDefault(requisiteId) {
    return apiClient
      .get(`/admin/accounts/requisites/${requisiteId}/default`)
      .then((response) => {
        return response.data.data
      })
  }

  deleteRequisite(requisiteId) {
    return apiClient
      .delete(`/admin/accounts/requisites/${requisiteId}`)
      .then((response) => {
        return response
      })
  }

  banClient(clientId, banData) {
    return apiClient
      .patch(`/admin/clients/${clientId}/ban`, banData)
      .then((response) => {
        return response.data.data
      })
  }

  unBanClient(clientId) {
    return apiClient
      .patch(`/admin/clients/${clientId}/unban`)
      .then((response) => {
        return response.data.data
      })
  }

  getSettings() {
    return apiClient
      .get('/admin/settings')
      .then((response) => {
        return response.data.data
      })
  }

  setSetting(settingName, value) {
    return apiClient
      .get(`/admin/settings/set/${settingName}/${value}`)
      .then((response) => {
        return response
      })
  }

  getAccountStats(accountId, month) {
    return apiClient
      .get(`/admin/accounts/${accountId}/stats?month=${month}`)
      .then((response) => {
        return response
      })
  }

  getAccountRewards(accountId, month, reqData) {
    return apiClient
      .get(`/admin/accounts/${accountId}/rewards?month=${month}`, { params: reqData })
      .then((response) => {
        return response
      })
  }

  getAccountAccruals(accountId, month, reqData) {
    return apiClient
      .get(`/admin/accounts/${accountId}/accruals?month=${month}`, { params: reqData })
      .then((response) => {
        return response
      })
  }

  deleteAccountAccrual(accrualId) {
    return apiClient
      .delete(`/admin/accounts/accruals/${accrualId}`)
      .then((response) => {
        return response
      })
  }

  createCurrency(currencyData) {
    return apiClient
      .post('/admin/currencies', currencyData)
      .then((response) => {
        return response
      })
  }

  updateCurrency(currencyId, currencyData) {
    return apiClient
      .patch(`/admin/currencies/${currencyId}`, currencyData)
      .then((response) => {
        return response
      })
  }

  deleteCurrency(currencyId) {
    return apiClient
      .delete(`/admin/currencies/${currencyId}`)
      .then((response) => {
        return response
      })
  }

  updateCurrencyRate(currencyRateId, rateData) {
    return apiClient
      .patch(`/admin/currencies/rates/${currencyRateId}`, rateData)
      .then((response) => {
        return response
      })
  }

  getBinanceRate(currency, sellAmount) {
    return apiClient
      .post(`/admin/binancio/${currency}`, { amount: sellAmount })
      .then((response) => {
        return response
      })
  }
}
export default new ApiService()
