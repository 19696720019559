<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="h5 mb-0"><a-icon type="global" /> Валюты</span>
      <a-button type="primary" @click="showNewCurrencyModal">
        <a-icon type="plus" /> Добавить
      </a-button>
    </div>
    <a-table :columns="currenciesColumns" :dataSource="currenciesData"
             :rowKey="record => record.id"
             :pagination="pagination"
             :loading="currencyLoading"
    >
      <template v-slot:name="name, record">
        {{ name }}
        <span v-if="record.desc" class="text-gray-5 small d-block" style="word-wrap: break-word;">{{ record.desc }}</span>
      </template>
      <template v-slot:abbr="abbr">
        {{ abbr }}
      </template>
      <template v-slot:rate="rate, record">
        <div v-if="rate" class="">
          <a-row>
            <a-col :span="6">
              <span class="small text-gray-5 d-block font-weight-light">Тип импорта:</span>
              <span>
                <a-tag :color="record.imp_r_type === 'binance' ? 'orange' : ''">{{ record.imp_r_type === 'default' ? 'ручной' : record.imp_r_type }}</a-tag>
                <a href="javascript:" @click="editCurrencyRate(record)"><a-icon type="edit" /></a>
              </span>
            </a-col>
            <a-col :span="6">
              <span class="small text-gray-5 d-block font-weight-light">Lanpay <a-icon type="line-chart" /></span>
              <span>{{ rate.custom_rate ? rate.custom_rate : rate.rate }} <span class="text-gray-5 small">{{ record.abbr }}</span></span>
            </a-col>
            <a-col :span="6">
              <span class="small text-gray-5 d-block font-weight-light">Агенты <a-icon type="team" /></span>
              <span>{{ rate.rate_a }} <span class="text-gray-5 small">{{ record.abbr }}</span></span>
            </a-col>
            <a-col :span="6">
              <span v-if="record.binancio_rate && record.imp_r_type !== 'default'">
                <span class="small text-gray-5 d-block font-weight-light">{{ record.imp_r_type.charAt(0).toUpperCase() + record.imp_r_type.slice(1) }}</span>
                <span>{{ record.binancio_rate.rate_o }} <span class="text-gray-5 small">{{ record.abbr }}</span></span>
              </span>
              <span v-else class="text-gray-5"></span>
            </a-col>
          </a-row>
        </div>
      </template>
      <template v-slot:operation="text, record">
          <span>
              <a-tooltip title="Активировать / Деактивировать" placement="bottom">
                <a-switch v-model="record.is_active" size="small" :cId="record.id" @change="currencyActiveState" />
              </a-tooltip>
          </span>
          <span class="ml-3">
              <a-tooltip title="Редактировать" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18 text-primary" @click="showEditCurrencyModal(record)"><a-icon type="edit" theme="filled" /></a>
              </a-tooltip>
          </span>
          <span class="ml-3">
            <a-tooltip title="Удалить" placement="bottom">
              <a-popconfirm
                :title="`Удалить валюту ${record.name}?`"
                @confirm="deleteCurrency(record)"
              >
                <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon class="text-danger" type="delete" theme="filled" /></a>
              </a-popconfirm>
            </a-tooltip>
          </span>
        </template>
    </a-table>
    <a-modal v-model="currencyModal"
             :destroyOnClose="true"
             :title="currencyObj ? `Изменить валюту '${currencyObj.name}'` : 'Добавить валюту'"
             :footer="null"
             class="currency-modal"
             width="680px"
    >
      <currency-modal
        :currency-info="currencyObj"
        @closeModalFunction="closeCurrencyModal"
        @submitModalFunction="currencyModalSubmit" />
    </a-modal>
    <a-modal v-model="currencyRateModal"
             :destroyOnClose="true"
             :title="currencyRateObj && currencyObj ? `Курс валюты к ${currencyObj.name}` : ''"
             :footer="null"
             class="currency-rate-modal"
             width="580px"
    >
      <currency-rate-modal
        :currency-rate-info="currencyRateObj"
        :currency="currencyObj"
        @closeModalFunction="closeCurrencyRateModal"
        @submitModalFunction="currencyRateModalSubmit" />
    </a-modal>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import currencyModal from '@/views/currencies/currencies/currencyModal.vue'
import currencyRateModal from '@/views/currencies/currencies/currencyRateModal.vue'
import ApiService from '@/services/api/api.service'

const currenciesColumns = [
  {
    title: 'Валюта',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Символ',
    dataIndex: 'abbr',
    key: 'abbr',
    width: '10%',
    scopedSlots: { customRender: 'abbr' },
  },
  {
    title: 'Курсы',
    dataIndex: 'rate',
    key: 'rate',
    width: '50%',
    scopedSlots: { customRender: 'rate' },
  },
  {
    title: 'Действия',
    dataIndex: 'operation',
    width: '25%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'currenciesTable',
  components: {
    currencyModal, currencyRateModal,
  },
  computed: {
    // ---
  },
  data() {
    return {
      currenciesData: [],
      currenciesColumns,
      currencyModal: false,
      currencyLoading: false,
      currencySubmitLoading: false,
      currencyRateModal: false,
      currencyObj: undefined,
      currencyRateObj: undefined,
      importRateType: undefined,
      pagination: { pageSize: 15 },
    }
  },
  mounted () {
    // ---
    this.getCurrencies()
  },
  methods: {
    showNewCurrencyModal() {
      this.currencyObj = null
      this.currencyModal = true
    },
    showEditCurrencyModal(currency) {
      this.currencyObj = currency
      this.currencyModal = true
    },
    editCurrencyRate(currency) {
      this.currencyObj = currency
      this.currencyRateObj = currency.rate
      this.currencyRateModal = true
    },
    closeCurrencyModal() {
      this.currencyModal = false
    },
    closeCurrencyRateModal() {
      this.currencyRateModal = false
    },
    currencyModalSubmit(currency, edit) {
      if (edit) {
        const psIndex = this.currenciesData.findIndex(obj => obj.id === currency.id)
        this.currenciesData[psIndex] = currency
        console.log(this.currenciesData)
      } else {
        console.log('new cur', currency)
        this.currenciesData.push(currency)
      }
      this.currencyModal = false
    },
    currencyRateModalSubmit(currency) {
      console.log('rate modal submitted', currency)
      const psIndex = this.currenciesData.findIndex(obj => obj.id === currency.id)
      this.currenciesData[psIndex] = currency
      this.currencyRateModal = false
    },
    async getCurrencies() {
      this.currencyLoading = true
      return ApiService.getCurrencies().then((response) => {
        this.currenciesData = response
        this.currencyLoading = false
      }).catch(error => { console.log(error); this.currencyLoading = false })
    },
    async deleteCurrency(currency) {
      return ApiService.deleteCurrency(currency.id).then(() => {
        this.$notification.success({
          message: 'Валюта удалена',
          description: currency.name,
        })
        const psIndex = this.currenciesData.findIndex(obj => obj.id === currency.id)
        this.currenciesData.splice(psIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при удалении валюты',
          description: error.message,
        })
      })
    },
    currencyActiveState(checked, event) {
      const cId = event.target.getAttribute('cId')
      const url = '/admin/currencies/' + cId + '/active/' + +checked
      apiClient.get(url).then((response) => {
        this.$notification.success({
          message: 'Изменен статус валюты',
          description: response.data.data.name,
        })
        const psIndex = this.currenciesData.findIndex(obj => obj.id === cId)
        this.currenciesData[psIndex] = response.data.data
        console.log(this.currenciesData[psIndex])
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка изменения статуса',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style scoped>
</style>
