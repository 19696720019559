<template>
  <div>
    <a-form-model
      ref="addAccountForm"
      :model="addAccountForm"
      :rules="addAccountRules"
    >
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="name" label="Имя агента" prop="name">
            <a-input v-model="addAccountForm.name" placeholder="Например Иван Иванов" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="6" class="pr-1 mt-0">
          <a-form-model-item ref="currency_id" label="Валюта" prop="currency_id">
<!--            <a-input v-model="addAccountForm.name" placeholder="Например Иван Иванов" />-->
            <a-select v-model="addAccountForm.currency_id"
                      placeholder="Выберите валюту"
                      :disabled="!activeCurrencies"
            >
              <a-select-option v-for="currency in activeCurrencies" :key="currency.id" >
                {{ currency.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1 pr-1">
          <a-form-model-item ref="bch_comm" label="Маржа" prop="bch_comm">
            <a-input-number
              v-model="addAccountForm.bch_comm"
              class="w-100"
              :default-value="4"
              :min="0"
              :max="50"
              :formatter="value => `${value}%`"
              :parser="value => value.replace('%', '')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1 pr-1">
          <a-form-model-item ref="min_amount_usd" label="Min сумма платежа" prop="min_amount_usd">
            <a-input-number
              v-model="addAccountForm.min_amount_usd"
              class="w-100"
              :default-value="500"
              :step="0.01"
              :min="1"
              :max="9999"
              :formatter="value => `${value}$`"
              :parser="value => value.replace('$', '')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1">
          <a-form-model-item ref="max_debt" label="Max сумма долга" prop="max_debt">
            <a-input-number
              v-model="addAccountForm.max_debt"
              class="w-100"
              :default-value="500"
              :min="1"
              :max="9999"
              :formatter="value => `${value}$`"
              :parser="value => value.replace('$', '')"
            />
          </a-form-model-item>
        </a-col>
<!--        <a-col :span="10" class="pl-1">-->
<!--          <a-form-model-item ref="is_coward" label="Тип отношений" prop="is_coward" class="w-100">-->
<!--            <div style="right: 0rem; top: -3rem;" class="position-absolute">-->
<!--              <a-tooltip title="При выборе типа 'Трусливый' агент будет получать bch адрес для оплаты отдельным сообщением и предложение оплатить счет через обменник" placement="bottom">-->
<!--                <a-icon type="question-circle" class="text-primary font-size-18" />-->
<!--              </a-tooltip>-->
<!--            </div>-->
<!--            <a-radio-group v-model="addAccountForm.is_coward" button-style="solid" class="w-100">-->
<!--              <a-radio-button :value="0" class="w-50">-->
<!--                Лояльный <span class="float-right">🐳</span>-->
<!--              </a-radio-button>-->
<!--              <a-radio-button :value="1" class="w-50">-->
<!--                Трусливый <span class="float-right">🐤</span>-->
<!--              </a-radio-button>-->
<!--            </a-radio-group>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
      </a-row>
      <a-row>
      </a-row>
      <a-row>
      </a-row>
      <a-row class="pb-0">
        <a-col :span="8" class="pr-1 pb-0">
          <a-form-model-item ref="email" label="Email" prop="email">
            <a-input v-model="addAccountForm.email" placeholder="" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="pl-1 pr-1 pb-0">
          <a-form-model-item ref="phone" label="№ телефона" prop="phone">
            <a-input v-model="addAccountForm.phone" placeholder="" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="pl-1 pb-0">
          <a-form-model-item ref="telegram" label="Telegram" prop="telegram">
            <a-input v-model="addAccountForm.telegram" placeholder="" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row>
      <a-col :span="14">
        <div>
          <a-checkbox v-model="addAccountForm.hc" name="hc">
            <i class="fe fe-message-circle font-size-14 text-primary" /> Разрешить чат
          </a-checkbox>
        </div>
        <div class="mt-1">
          <a-checkbox v-model="addAccountForm.max_debt_inc" name="max_debt_inc">
            <i class="fe fe-trending-up font-size-14 text-primary" /> Увеличивать Долг<sub><b>max</b></sub> на сумму баланса
          </a-checkbox>
        </div>
      </a-col>
      <a-col :span="10" class="text-right">
        <div class="text-right mt-3">
          <a-button type="primary" @click="onSubmit" :loading="submitLoading">
            {{ accountInfo ? 'Сохранить' : 'Добавить' }}
          </a-button>
          <a-button style="margin-left: 1rem;" @click="closeModal">
            Отмена
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import { mapGetters } from 'vuex'

export default {
  name: 'addAccount',
  props: ['accountInfo'],
  computed: {
    ...mapGetters(['activeCurrencies']),
  },
  data() {
    return {
      submitLoading: false,
      addAccountForm: {
        name: this.accountInfo ? this.accountInfo.name : '',
        currency_id: this.accountInfo ? this.accountInfo.currency_id : 1,
        is_coward: this.accountInfo ? this.accountInfo.is_coward : 0,
        telegram: this.accountInfo?.contact ? this.accountInfo.contact.telegram : '',
        email: this.accountInfo?.email ? this.accountInfo.contact.email : '',
        phone: this.accountInfo?.phone ? this.accountInfo.contact.phone : '',
        bch_comm: this.accountInfo ? this.accountInfo.bch_comm : 0,
        max_debt: this.accountInfo ? this.accountInfo.max_debt : 500,
        max_debt_inc: this.accountInfo ? this.accountInfo.max_debt_inc : true,
        min_amount_usd: this.accountInfo ? this.accountInfo.min_amount_usd : 1,
        hc: this.accountInfo ? this.accountInfo.hc : true,
      },
      addAccountRules: {
        name: [{ required: true, message: 'Укажите имя аккаунта', trigger: 'change' }],
        currency_id: [{ required: true, message: 'Укажите валюту', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    onSubmit() {
      this.$refs.addAccountForm.validate(valid => {
        if (valid) {
          this.accountInfo ? this.editAccountSubmit() : this.addAccountSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.addAccountForm.resetFields()
    },
    addAccountSubmit() {
      this.submitLoading = true
      const url = '/admin/accounts'
      return apiClient.post(url, this.addAccountForm).then((response) => {
        this.$notification.success({
          message: 'Создан новый аккаунт',
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при создании аккаунта',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    editAccountSubmit() {
      this.submitLoading = true
      const url = '/admin/accounts/' + this.accountInfo.id
      return apiClient.patch(url, this.addAccountForm).then((response) => {
        this.$notification.success({
          message: 'Аккаунт обновлен',
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data, true)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при обновлении аккаунта',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    randomString(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
  },
}
</script>

<style scoped>
</style>
