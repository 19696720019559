<template>
  <div>
    <a-form-model
      ref="addRequisiteForm"
      :model="addRequisiteForm"
      :rules="addRequisiteRules"
    >
      <a-row>
        <a-col :span="12" class="pr-1">
          <a-form-model-item ref="psystem_id" label="Банк/платежная система" prop="psystem_id">
            <a-select v-model="addRequisiteForm.psystem_id"
                      placeholder="Выберите платежную систему"
                      @change="handlePsystemChange"
                      :loading="psystemsLoading"
                      :disabled="psystemsLoading"
            >
<!--              <a-select-option :value="0" >-->
<!--                Выберите платежную систему-->
<!--              </a-select-option>-->
              <a-select-option v-for="psystem in psystems" :key="psystem.id" >
                <span role="img">
                  <img :src="psystem.img_path" width="16" height="16" >
                </span>
                {{ psystem.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1 pr-1">
          <a-form-model-item ref="currency_id" label="Валюта" prop="currency_id">
            <a-select v-model="addRequisiteForm.currency_id"
                      placeholder="Выберите валюту"
                      @change="handleCurrencyChange"
                      :disabled="!addRequisiteForm.psystem_id"
            >
              <a-select-option v-for="currency in currencies" :key="currency.id" >
                {{ currency.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1">
          <a-form-model-item ref="max_amount" label="Лимит платежа" prop="max_amount">
            <a-input-number
              v-model="addRequisiteForm.max_amount"
              class="w-100"
              :default-value="1000"
              :min="1"
              :max="100000"
              :formatter="value => currency && currency.abbr ? `${value}${currency.abbr}` : `${value} `"
              :parser="value => value.replace(currency && currency.abbr ? `${currency.abbr}` : ' ', '')"
              :disabled="!(addRequisiteForm.psystem_id > 0 && addRequisiteForm.currency_id > 0)"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12" class="pr-1">
          <a-form-model-item ref="address" label="Адрес получателя" prop="address">
            <a-input v-model="addRequisiteForm.address" placeholder="Например номер карты, счета, телефона" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-1">
          <a-form-model-item ref="name" label="Имя получателя" prop="name">
            <a-input v-model="addRequisiteForm.name" placeholder="Например Иванов Иван Иванович" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="comment" label="Комментарий" prop="comment">
            <a-textarea v-model="addRequisiteForm.comment" placeholder="Эту информацию увидит клиент с реквизитом платежа" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <span>
            Активировать
            <a-switch v-model="addRequisiteForm.is_active" />
          </span>
        </a-col>
      </a-row>
    </a-form-model>
    <hr/>
    <a-row class="mt-4">
      <a-col :span="8">
        <a-button @click="closeModal">
          Отмена
        </a-button>
      </a-col>
      <a-col :span="16" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          {{ requisiteInfo ? 'Сохранить' : 'Добавить' }}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import ApiService from '@/services/api/api.service'

export default {
  name: 'addRequisite',
  props: ['requisiteInfo', 'accountObj'],
  computed: {
    // ---
  },
  data() {
    return {
      submitLoading: false,
      psystemsLoading: false,
      psystems: undefined,
      currencies: this.requisiteInfo ? this.requisiteInfo.psystem.currencies : undefined,
      currency: this.requisiteInfo ? this.requisiteInfo.currency : undefined,
      addRequisiteForm: {
        name: this.requisiteInfo ? this.requisiteInfo.name : undefined,
        address: this.requisiteInfo ? this.requisiteInfo.address : undefined,
        account_id: this.accountObj.id,
        psystem_id: this.requisiteInfo ? this.requisiteInfo.psystem_id : undefined,
        currency_id: this.requisiteInfo ? this.requisiteInfo.currency_id : undefined,
        max_amount: this.requisiteInfo ? this.requisiteInfo.max_amount : 1000,
        is_active: this.requisiteInfo ? this.requisiteInfo.is_active : 0,
        comment: this.requisiteInfo ? this.requisiteInfo.comment : '',
      },
      addRequisiteRules: {
        name: [{ required: true, message: 'Укажите имя получателя', trigger: 'change' }],
        address: [{ required: true, message: 'Укажите адрес получателя', trigger: 'change' }],
        psystem_id: [{ required: true, message: 'Укажите платежную систему', trigger: 'change' }],
        currency_id: [{ required: true, message: 'Укажите валюту', trigger: 'change' }],
      },
    }
  },
  mounted () {
    this.getPsystems()
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    onSubmit() {
      this.$refs.addRequisiteForm.validate(valid => {
        if (valid) {
          this.requisiteInfo ? this.editRequisiteSubmit() : this.addRequisiteSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.addRequisiteForm.resetFields()
    },
    handlePsystemChange(objId) {
      const ps = this.psystems.find(obj => obj.id === objId)
      this.currencies = this.accountObj.id === 999 ? ps.currencies : ps.currencies.filter((cur) => cur.id === this.accountObj.currency_id)
      if (typeof this.currencies[0] !== 'undefined') {
        this.addRequisiteForm.currency_id = this.currencies[0].id
        this.currency = this.currencies[0]
      }
      console.log(this.currencies)
    },
    handleCurrencyChange(objId) {
      const cur = this.currencies.find(obj => obj.id === objId)
      this.currency = cur
      console.log(this.currency)
    },
    addRequisiteSubmit() {
      this.submitLoading = true
      const url = '/admin/accounts/requisites'
      return apiClient.post(url, this.addRequisiteForm).then((response) => {
        this.$notification.success({
          message: 'Добавлены реквизиты',
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при добавлении реквизитов',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    editRequisiteSubmit() {
      this.submitLoading = true
      const url = '/admin/accounts/requisites/' + this.requisiteInfo.id
      return apiClient.patch(url, this.addRequisiteForm).then((response) => {
        this.$notification.success({
          message: 'Реквизиты обновлены',
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data, true)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при обновлении реквизитов',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    async getPsystems() {
      this.psystemsLoading = true
      return ApiService.getPsystems(true, this.accountObj.currency_id).then(
        response => {
          this.psystems = response.filter(obj => obj.id !== 6)
          console.log('getPsystems', this.psystems)
          this.psystemsLoading = false
        },
        error => {
          this.psystemsLoading = false
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
