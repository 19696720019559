import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import ps from './ps'
import ApiService from '../services/api/api.service'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
    ps,
  },
  state: {
    currencies: [],
    psystems: [],
    activeProjectList: [],
  },
  mutations: {
    setCurrencies (state, currencies) {
      state.currencies = currencies
    },
    setPsystems (state, psystems) {
      state.psystems = psystems
    },
    setActiveProjectList (state, projects) {
      state.activeProjectList = projects
    },
    addPsystem (state, psystem) {
      state.psystems.unshift(psystem)
    },
    updatePsystem(state, psystem) {
      const ind = state.psystems.findIndex(obj => obj.id === psystem.id)
      state.psystems[ind] = psystem
    },
    deletePsystem(state, psystemId) {
      const ind = state.psystems.findIndex(obj => obj.id === psystemId)
      state.psystems.splice(ind, 1)
    },
  },
  actions: {
    //
    getCurrencies({ commit, dispatch }) {
      return ApiService.getCurrencies().then(
        response => {
          commit('setCurrencies', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getPsystems({ commit, dispatch }) {
      return ApiService.getPsystems().then(
        response => {
          commit('setPsystems', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    getActiveProjectList({ commit, dispatch }) {
      return ApiService.getProjects(false, true).then(
        response => {
          commit('setActiveProjectList', response)
          return response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
  },
  getters: {
    currencies: state => state.currencies,
    activeCurrencies: state => state.currencies.filter(obj => obj.is_active === true),
    psystems: state => state.psystems,
    activePsystems: state => state.psystems.filter(obj => obj.is_active === true),
    activeProjectList: state => state.activeProjectList,
  },
})
