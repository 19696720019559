<template>
  <div>
    <div class="settings-content" v-if="!settingsLoading">
      <a-card>
        <p><i class="fe fe-trending-down font-size-18 text-primary" /> Маржа для BCH счетов</p>
        <div class="d-flex">
          <div class="pr-2 w-50">
            <a-select v-model="bchGlobalSubComm" class="w-100">
              <a-select-option value="0">
                Индивидуальная
              </a-select-option>
              <a-select-option value="1">
                Глобальная
              </a-select-option>
            </a-select>
          </div>
          <div class="w-50 pr-2" v-if="bchGlobalSubComm === '1'">
            <a-input-number
              v-model="bchGlobalSubCommValue"
              class="w-100"
              :default-value="4"
              :min="0"
              :max="50"
              :formatter="value => `${value}%`"
              :parser="value => value.replace('%', '')"
            />
          </div>
          <div class="ml-auto">
            <a-button type="primary" @click="setBchCommissionSettings" >Сохранить</a-button>
          </div>
        </div>
      </a-card>
      <a-card class="mt-2">
        <div class="d-flex">
          <div class="mr-auto">
            <i class="fe fe-smartphone font-size-18 text-primary" /> Мобильный интерфейс
          </div>
          <div>
            <a-switch v-model="mobileUIEnabled" @change="setMobileUiEnabled" />
          </div>
        </div>
      </a-card>
      <a-card class="mt-2">
        <div class="d-flex">
          <div class="mr-auto">
            <i class="fe fe-message-circle font-size-18 text-primary" /> Чат клиент-агент
          </div>
          <div>
            <a-switch v-model="clientAgentChatEnabled" @change="setClientAgentChatEnabled" />
          </div>
        </div>
      </a-card>
    </div>
    <div v-else class="text-center width-100p"><a-spin size="large" /></div>
  </div>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'settingsModal.vue',
  computed: {
    // ---
  },
  data() {
    return {
      submitLoading: false,
      settings: null,
      settingsLoading: false,
      mobileUIEnabled: false,
      clientAgentChatEnabled: false,
      bchGlobalSubComm: '1',
      bchGlobalSubCommValue: 4,
    }
  },
  mounted () {
    this.getSettings().then(() => {
      this.prepareSettingValues()
      console.log(this.settings)
    })
  },
  methods: {
    async getSettings() {
      this.settingsLoading = true
      return ApiService.getSettings().then((response) => {
        this.settings = response.reduce(function(map, obj) {
          map[obj.field] = obj.value
          return map
        }, {})
        this.settingsLoading = false
      }).catch(error => { console.log(error); this.settingsLoading = false })
    },
    prepareSettingValues() {
      this.mobileUIEnabled = Boolean(parseInt(this.settings.mobile_ui_enabled))
      this.clientAgentChatEnabled = Boolean(parseInt(this.settings.client_agent_chat_enabled))
      this.bchGlobalSubComm = this.settings.bch_comm_sub_global
      this.bchGlobalSubCommValue = parseInt(this.settings.bch_comm_sub_value)
    },
    async setMobileUiEnabled() {
      return ApiService.setSetting('mobile_ui_enabled', Number(this.mobileUIEnabled)).then((response) => {
        this.$notification.success({
          message: 'Мобильный интерфейс ' + (this.mobileUIEnabled ? 'включен' : 'выключен'),
          description: '',
        })
      }).catch(error => { console.log(error) })
    },
    async setClientAgentChatEnabled() {
      return ApiService.setSetting('client_agent_chat_enabled', Number(this.clientAgentChatEnabled)).then((response) => {
        this.$notification.success({
          message: 'Чат ' + (this.clientAgentChatEnabled ? 'включен' : 'выключен'),
          description: '',
        })
      }).catch(error => { console.log(error) })
    },
    setBchCommissionSettings() {
      this.setBchCommissionGlobal().then(() => this.setBchCommissionValue())
    },
    async setBchCommissionGlobal() {
      return ApiService.setSetting('bch_comm_sub_global', this.bchGlobalSubComm).then((response) => {
        // console.log('success')
      }).catch(error => { console.log(error) })
    },
    async setBchCommissionValue() {
      return ApiService.setSetting('bch_comm_sub_value', this.bchGlobalSubCommValue).then((response) => {
        this.$notification.success({
          message: 'Настройки маржи сохранены',
          description: '',
        })
      }).catch(error => { console.log(error) })
    },
  },
}
</script>
<style lang="scss">
</style>
<style scoped>
</style>
