<template>
  <div>
    <a-card class="mb-4">
      <div class="d-flex mb-4">
        <span class="h5 mb-0"><font-awesome-icon icon="chart-area" class="mt-1 mr-1 font-size-24" /> Аналитика</span>
        <div class="ml-auto">
<!--          <a-button @click="test">test</a-button>-->
<!--          <a-button> Применить</a-button>-->
<!--          <a-button type="primary" @click="showCreateReportModal"><a-icon type="file-excel" /> Экспорт</a-button>-->
        </div>
      </div>
      <div class="d-flex mb-3">
        <div>
          <a-icon type="api" />
          :
          <a-select v-model="projectId" placeholder="Проект" class="width-150" @change="handleProjectChange">
            <a-select-option :value="0" >
              [Все проекты]
            </a-select-option>
            <a-select-option v-for="project in activeProjectList" :key="project.id" >
              <a-badge status="success" v-if="project.is_active" />
              <a-badge color="grey" v-else />
              {{ project.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-icon type="user" />
          :
          <a-select v-model="accountId" placeholder="Выберите агента" class="width-150" @change="handleAgentChange">
            <a-select-option :value="0" >
              [Все агенты]
            </a-select-option>
            <a-select-option v-for="account in accountsData" :key="account.id" >
              {{ account.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-icon type="calendar" />
          :
          <a-date-picker
            v-model="from_date"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            type="date"
            placeholder="Выберите начальную дату отчета"
            style="width: 160px"
            :default-value="this.$moment('2021/10/20', 'YYYY-MM-DD HH:mm:ss')"
            class="mr-1"
            @openChange="handleDatesChange"
          />
          -
          <a-date-picker
            v-model="to_date"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            type="date"
            placeholder="Выберите конечную дату отчета"
            style="width: 160px;"
            :default-value="this.$moment('2022/01/20', 'YYYY-MM-DD HH:mm:ss')"
            class="ml-1"
            @openChange="handleDatesChange"
          />
<!--          <a-button class="ml-2" @click="handleDatesChange">Применить</a-button>-->
        </div>
        <div class="ml-auto">
          <a-button type="primary" @click="showCreateReportModal"><a-icon type="file-excel" /> Экспорт</a-button>
        </div>
      </div>
<!--      <h5>Платежи</h5>-->
      <div class="mt-1 mb-3">
        <a-row :gutter="16">
          <a-col :span="4">
            <a-statistic title="Всего" :value="statData.count" style="margin-right: 50px">
<!--                                  <template #suffix>-->
<!--                                    <a-icon type="like" />-->
<!--                                  </template>-->
            </a-statistic>
          </a-col>
          <a-col :span="4">
            <a-statistic title="Сумма" :value="statData.sum_amount ? statData.sum_amount : 0" class="demo-class">
            </a-statistic>
          </a-col>
          <a-col :span="4">
            <a-statistic title="Сумма в USD" :value="statData.sum_usd ? statData.sum_usd : 0" style="margin-right: 50px">
            </a-statistic>
          </a-col>
        </a-row>
      </div>
      <a-table :columns="recordColumns"
               :dataSource="recordsData"
               :pagination="pagination"
               :loading="recordsTableLoading"
               @change="handleTableChange">
        <template v-slot:paid_at="paid_at">
          <span v-if="paid_at">
            {{ $moment(paid_at).format('DD.MM.YY') }}
            <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment(paid_at).format('HH:mm') }}</span>
          </span>
        </template>
        <template v-slot:login="login">
          <span v-if="login">{{ login }}</span>
        </template>
        <template v-slot:amount="amount, record">
          <span v-if="record.psystem" class="mr-1">
            <a-tooltip :title="record.psystem.name" placement="bottom">
              <img :src="record.psystem.img_path" width="18" height="18" />
            </a-tooltip>
          </span>
          <span v-if="amount">{{ amount }}</span>
        </template>
        <template v-slot:amount_usd="amount_usd, record">
          <a-tooltip v-if="userHasRole('admin')" title="По агентскому курсу" placement="bottom">
            <a-tag v-if="record.amount_usd_a">{{ record.amount_usd_a }} $</a-tag>
          </a-tooltip>
          <a-tooltip title="По курсу Binance" placement="bottom">
            <a-tag v-if="amount_usd">{{ amount_usd }} $</a-tag>
          </a-tooltip>
        </template>
        <template v-slot:currency="currency">
          {{ currency }}
        </template>
<!--        <span slot="agent_pay_usd" slot-scope="agent_pay_usd, record">-->
<!--          <span v-if="agent_pay_usd">-->
<!--            <span v-if="!record.agent_paid">-->
<!--              <span class="text-gray-5">{{ record.amount_usd }} $</span>-->
<!--              <a-tooltip title="Не оплачен" placement="bottom" class="ml-2">-->
<!--                <a-icon  type="warning"  class="text-warning" />-->
<!--              </a-tooltip>-->
<!--            </span>-->
<!--            <span v-else>{{ agent_pay_usd }} $</span>-->
<!--          </span>-->
<!--        </span>-->
        <span slot="agent_name" slot-scope="agent_name">
          <span v-if="agent_name">{{ agent_name }}</span>
          <span v-else class="text-danger">Удален</span>
        </span>
        <span slot="promo" slot-scope="promo, record">
          <span v-if="promo">
            <a-tooltip v-if="record.is_activated" title="Активирован" placement="bottom">
              <a-icon  type="check"  class="text-success" />
            </a-tooltip>
            <a-tooltip v-else title="Не активирован" placement="bottom">
              <a-icon  type="warning"  class="text-warning" />
            </a-tooltip>
            {{ promo }}
          </span>
        </span>
        <template v-slot:activated_at="activated_at">
          <span v-if="activated_at">
            {{ $moment(activated_at).format('DD.MM.YY') }}
            <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment(activated_at).format('HH:mm') }}</span>
          </span>
        </template>
      </a-table>
      <div class="analytics_reports" v-if="userHasRole('admin')">
        <div class="d-flex mb-4">
          <span class="h5 mb-0"><a-icon type="stock" /> Отчеты</span>
          <div class="ml-auto">
          </div>
        </div>
        <a-table :columns="reportColumns"
                 :dataSource="reportsData"
                 :pagination="reportsPagination"
                 :loading="reportsTableLoading"
                 @change="handleReportsTableChange"
        >
          <span slot="created_at" slot-scope="created_at">
            <span v-if="created_at">{{ $moment(created_at).format('DD.MM.YY HH:mm') }}</span>
          </span>
            <span slot="from_date" slot-scope="from_date, record">
            <span v-if="from_date">
              <a-icon type="calendar" />
              {{ $moment(from_date).format('DD.MM.YY HH:mm') }} - {{ $moment(record.to_date).format('DD.MM.YY HH:mm') }}
            </span>
          </span>
            <span slot="account" slot-scope="account, record">
            <span v-if="record.account_id > 0">
              <span v-if="account">
                <a-icon type="user" />
                {{ account }}
              </span>
              <span v-else>[удален]</span>
            </span>
            <span v-else>Все агенты</span>
          </span>
            <span slot="file_path" slot-scope="file_path">
            <span v-if="file_path">
              <a-icon type="file-excel" class="text-success" />
              {{ file_path.replace(/^.*[\\/]/, '') }}
            </span>
          </span>
            <span slot="operation" slot-scope="text, record">
            <span class="font-size-18">
              <a href="javascript:" @click="downloadFileByUrl(record.file_path)">
                    <a-tooltip title="Скачать" placement="bottom">
                      <a-icon type="download" class="text-primary" />
                    </a-tooltip>
                  </a>
            </span>
          </span>
        </a-table>
      </div>
    </a-card>
    <a-modal
      v-model="createReportModal"
      title="Создать файл с отчетом"
      @ok="createReportSubmit"
      :footer="null"
      class="report-modal"
      width="600px"
      :destroyOnClose="true"
    >
      <create-report-component :from-date="from_date" :to-date="to_date"
            :accounts="accountsData" :account-id="accountId" :project-id="projectId"
            @closeModalFunction="closeCreateReportModal" @submitModalFunction="createReportSubmit" />
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import apiClient from '@/services/axios'
import createReportComponent from '@/views/analytics/createReportModal.vue'
import accessMix from '@/services/accessmix.js'

const recordColumns = [
  {
    title: 'Дата оплаты',
    dataIndex: 'paid_at',
    key: 'paid_at',
    scopedSlots: { customRender: 'paid_at' },
  },
  {
    title: 'Логин',
    dataIndex: 'login',
    key: 'login',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    key: 'amount',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Валюта',
    dataIndex: 'currency',
    key: 'currency',
    scopedSlots: { customRender: 'currency' },
  },
  {
    title: 'Курс',
    dataIndex: 'currency_rate',
    key: 'currency_rate',
    scopedSlots: { customRender: 'currency_rate' },
  },
  {
    title: 'Сумма в USD',
    dataIndex: 'amount_usd',
    key: 'amount_usd',
    scopedSlots: { customRender: 'amount_usd' },
  },
  // {
  //   title: 'Оплачено агентом',
  //   dataIndex: 'agent_pay_usd',
  //   key: 'agent_pay_usd',
  //   scopedSlots: { customRender: 'agent_pay_usd' },
  // },
  {
    title: 'Агент',
    dataIndex: 'agent_name',
    key: 'agent_name',
    scopedSlots: { customRender: 'agent_name' },
  },
  {
    title: 'Промокод',
    dataIndex: 'promo',
    key: 'promo',
    scopedSlots: { customRender: 'promo' },
  },
  {
    title: 'Активирован',
    dataIndex: 'activated_at',
    key: 'activated_at',
    scopedSlots: { customRender: 'activated_at' },
  },
]
const reportColumns = [
  {
    title: 'Создан',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Период',
    dataIndex: 'from_date',
    key: 'from_date',
    scopedSlots: { customRender: 'from_date' },
  },
  {
    title: 'Агент',
    dataIndex: 'account',
    key: 'account',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: 'Файл',
    dataIndex: 'file_path',
    key: 'file_path',
    scopedSlots: { customRender: 'file_path' },
  },
  {
    title: '',
    dataIndex: 'operation',
    // width: '18%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]
export default {
  name: 'analytics',
  mixins: [accessMix],
  components: {
    createReportComponent,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['activeProjectList']),
  },
  data() {
    return {
      recordColumns,
      reportColumns,
      recordsData: [],
      reportsData: [],
      accountsData: [],
      statData: { count: 0, sum_amount: 0, sum_usd: 0 },
      recordsTableLoading: false,
      reportsTableLoading: false,
      statLoading: false,
      pagination: { pageSize: 10 },
      reportsPagination: { pageSize: 5 },
      searchField: 'login',
      searchValue: '',
      accountId: 0,
      projectId: 0,
      createReportModal: false,
      from_date: this.$moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      to_date: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
    }
  },
  mounted () {
    this.getAccounts().then(() => { this.refreshList(); this.getStatByPeriod() })
    // this.fetch()
    this.fetchReports()
  },
  methods: {
    showCreateReportModal() {
      this.createReportModal = true
    },
    closeCreateReportModal() {
      this.createReportModal = false
    },
    createReportSubmit(record, download) {
      this.createReportModal = false
      this.fetchReports({
        results: this.pagination.pageSize,
        page: this.pagination.current,
      })
      if (download) {
        // console.log('try to download')
        setTimeout(() => { this.downloadFileByUrl(record.file_path) }, 500)
      }
    },
    downloadFileByUrl(url) {
      // console.log('start download')
      const fileLink = document.createElement('a')
      fileLink.href = url
      fileLink.setAttribute('download', url.replace(/^.*[\\/]/, ''))
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async getStatByPeriod() {
      this.statLoading = true
      const url = '/admin/analytics/digits'
      const statParams = {
        account_id: this.accountId,
        from_date: this.$moment(this.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        project_id: this.projectId,
      }
      return apiClient.get(url, { params: statParams }).then((response) => {
        this.statData = response.data.data
        console.log(this.statData)
        this.statLoading = false
      }).catch(error => { console.log(error); this.statLoading = false })
    },
    handleDatesChange(open) {
      if (!open) {
        this.pagination.current = 1
        this.refreshList()
        this.getStatByPeriod()
      }
    },
    handleProjectChange() {
      this.refreshList()
      this.getStatByPeriod()
    },
    handleAgentChange() {
      this.pagination.current = 1
      this.refreshList()
      this.getStatByPeriod()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
        from_date: this.$moment(this.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        account_id: this.accountId,
        project_id: this.projectId,
      })
    },
    handleReportsTableChange(pagination, filters) {
      const pager = { ...this.reportsPagination }
      pager.current = pagination.current
      this.reportsPagination = pager
      this.fetchReports({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
      })
    },
    fetch(params = {}) {
      // console.log('params:', params)
      this.recordsTableLoading = true
      const reqData = { results: 20, ...params }
      console.log(reqData)
      const url = '/admin/analytics'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        console.log(response)
        pagination.total = response.data.meta.total
        this.recordsTableLoading = false
        this.recordsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.recordsTableLoading = false
      })
    },
    fetchReports(params = {}) {
      this.reportsTableLoading = true
      const reqData = { results: 10, ...params }
      console.log(reqData)
      const url = '/admin/analytics/reports'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.reportsPagination }
        console.log(response)
        pagination.total = response.data.meta.total
        this.reportsTableLoading = false
        this.reportsData = response.data.data
        this.reportsPagination = pagination
      }).catch(error => {
        console.log(error)
        this.reportsTableLoading = false
      })
    },
    refreshList() {
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        project_id: this.projectId,
        account_id: this.accountId,
        from_date: this.$moment(this.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      })
      // console.log(this.pagination)
    },
    async getRecords() {
      this.recordsTableLoading = true
      const url = '/admin/analytics'
      return apiClient.get(url).then((response) => {
        this.recordsData = response.data.data
        console.log(this.recordsData)
        this.recordsTableLoading = false
      }).catch(error => { console.log(error); this.recordsTableLoading = false })
    },
    async getAccounts() {
      const url = '/admin/accounts?short'
      return apiClient.get(url).then((response) => {
        this.accountsData = response.data.data
        console.log('accounts', this.accountsData)
      }).catch(error => { console.log(error) })
    },
  },
}

</script>
<style scoped>
  .editable-row-operations a {
    margin-right: 8px;
  }
</style>
