<template>
  <div :class="$style.wrapperLog">
    <div class="text-center">
      <h1 :class="$style.logo">
        <span class="text-white">{{ settings.logo }}</span>
      </h1>
    </div>
    <div class="card" :class="$style.containerLog">
      <div :class="$style.title">
<!--        Log in to your account-->
        Авторизация
      </div>
      <div>
        <a-radio-group
          :value="settings.authProvider"
          @change="e => changeAuthProvider(e.target.value)"
        >
<!--          <a-radio value="firebase" disabled>Firebase</a-radio>-->
          <a-radio value="jwt" hidden>JWT</a-radio>
<!--          <a-tooltip>-->
<!--            <template slot="title">-->
<!--              <span>Read Docs Guide</span>-->
<!--            </template>-->
<!--            <a-radio value="auth0" disabled>Auth0</a-radio>-->
<!--          </a-tooltip>-->
<!--          <a-tooltip>-->
<!--            <template slot="title">-->
<!--              <span>Read Docs Guide</span>-->
<!--            </template>-->
<!--            <a-radio value="strapi" disabled>Strapi</a-radio>-->
<!--          </a-tooltip>-->
        </a-radio-group>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
            size="large"
            placeholder="Email"
            v-decorator="['email', {rules: [{ required: true, message: 'Please input your username!' }]}]"
          >
            <a-icon slot="prefix" type="user" class="text-primary" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input-password
            v-decorator="['password', {rules: [{ required: true, message: 'Please input your Password!' }]}]"
            size="large"
            placeholder="Password"
            type="password"
          >
            <a-icon slot="prefix" type="unlock" class="text-primary" />
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <div :class="$style.checkbox">
            <a-checkbox
              v-decorator="[
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                },
              ]"
            >
<!--              Remember me-->Запомнить
            </a-checkbox>
<!--            <span class="float-right">-->
<!--              <router-link to="/auth/forgot-password" class="kit__utils__link">Forgot Password</router-link>-->
<!--            </span>-->
          </div>
        </a-form-item>
        <a-button
          type="primary"
          htmlType="submit"
          size="large"
          :class="$style.button"
          :loading="loading"
        >
<!--          Log in-->Войти
        </a-button>
<!--        <div class="text-center mt-4">-->
<!--          <span class="mr-1">New for Snuffer.net?</span>-->
<!--          <router-link to="/auth/register" class="kit__utils__link">Register now</router-link>-->
<!--        </div>-->
      </a-form>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'CuiLogin',
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      passwordVisibility: false,
      form: this.$form.createForm(this),
    }
  },
  methods: {
    changeAuthProvider(value) {
      this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('user/LOGIN', { payload: values })
        }
      })
    },
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
