export const getMenuData = [
  {
    category: true,
    title: 'Главное меню',
  },
  {
    title: 'Заявки',
    key: 'payments',
    url: '/payments',
    icon: 'fe fe-credit-card',
    roles: ['admin'],
  },
  {
    title: 'Агенты',
    key: 'accounts',
    url: '/accounts',
    icon: 'fe fe-users',
    roles: ['admin'],
  },
  {
    title: 'Клиенты',
    key: 'clients',
    url: '/clients',
    icon: 'fe fe-users',
    roles: ['admin'],
  },
  {
    title: 'Пользователи',
    key: 'users',
    url: '/users',
    icon: 'fe fe-users', // fe fe-layers
    hidden: true,
    roles: ['admin'],
  },
  {
    title: 'Транзакции',
    key: 'transactions',
    url: '/transactions',
    icon: 'team',
    hidden: true,
    roles: ['admin'],
  },
  {
    title: 'Crypto-Счета',
    key: 'invoices',
    url: '/invoices',
    icon: 'fe fe-dollar-sign',
    roles: ['admin'],
  },
  {
    title: 'Аналитика',
    key: 'analytics',
    url: '/analytics',
    icon: 'fe fe-trending-up',
  },
  // {
  //   title: 'Промо-коды',
  //   key: 'promocodes',
  //   url: '/promocodes',
  //   icon: 'tags',
  //   children: [
  //     {
  //       title: 'Список промо-кодов',
  //       key: 'promocode-list',
  //       url: '/promocodes/list/',
  //       icon: 'tags',
  //     },
  //   ],
  // },
  {
    title: 'Промо-коды',
    key: 'promocodes-list',
    url: '/promocodes/list/1',
    icon: 'tags',
    roles: ['admin'],
    hidden: true,
  },
  {
    title: 'Настройки',
    key: 'settings',
    url: '/settings',
    icon: 'team',
    roles: ['admin'],
    hidden: true,
  },
  {
    title: 'Банки и валюты',
    key: 'psystems',
    url: '/psystems',
    icon: 'team',
    roles: ['admin'],
    hidden: true,
  },
]
