<template>
  <div class="clients-content">
<!--    <a-row>-->
<!--      <a-col :span="16">-->
<!--        <h4 class="text-primary"><font-awesome-icon icon="file-invoice-dollar" /> Счета</h4>-->
<!--      </a-col>-->
<!--      <a-col :span="8" class="text-right">-->
<!--        <a-button :loading="transactionsLoading" @click="getTransactions"><a-icon type="reload" /> Обновить</a-button>-->
<!--      </a-col>-->
<!--    </a-row>-->
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <span class="h5 mb-0"><font-awesome-icon icon="user-friends" class="mt-1 mr-1 font-size-24" /> Клиенты</span>
      </div>
      <div class="d-flex mb-3">
        <div>
          <a-icon type="api" />
          :
          <a-select v-model="projectId" placeholder="Проект" class="width-200" @change="handleProjectChange">
            <a-select-option :value="0" >
              [Все проекты]
            </a-select-option>
            <a-select-option v-for="project in activeProjectList" :key="project.id" >
              <a-badge status="success" v-if="project.is_active" />
              <a-badge color="grey" v-else />
              {{ project.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-icon type="search" class="mr-1" />
          <a-input-search
            v-model="searchValue"
            placeholder="Укажите логин клиента"
            style="width: 280px"
            allowClear
            @search="searchByInput"
          >
          </a-input-search>
        </div>
        <div class="ml-auto">
          <a-button :loading="clientsTableLoading" @click="refreshList"><a-icon type="reload" /> Обновить</a-button>
        </div>
      </div>
      <a-table
        :columns="invoiceColumns"
        :dataSource="clientsData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="clientsTableLoading"
        @change="handleTableChange"
      >
        <template v-slot:login="login, record">
          <span v-if="login">
            <div class="d-flex">
              <span v-if="record.is_auth"><a-badge status="success" /></span>
              <span v-else><a-badge color="grey" /></span>
              <div>
                {{ login }}
                <span v-if="record.short_name" class="d-block text-gray-5">
                  {{ record.short_name }}
                </span>
              </div>
              <div class="ml-auto mt-2 pt-1">
                <span v-if="record.ban" class="flex">
                  <span class="mt-1">
                    <a-tooltip :title="`⚠ Клиент заблокирован\nс ${$moment(record.ban.ban_at).format('DD.MM.YY HH:mm')}\nпо ${$moment(record.ban.unban_at).format('DD.MM.YY HH:mm')}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
                      <font-awesome-icon icon="ban" class="text-danger font-size-18" />
                    </a-tooltip>
                  </span>
                  <span class="ml-1">
                    <a-tooltip :title="`Комментарий:\n${record.ban.comment}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
                      <a href="javascript:" class="text-gray-5 font-size-12" @click="clipboardHandle(record.ban.comment)">
<!--                        <font-awesome-icon icon="info"  />-->
                        <span v-if="record.ban.comment">
                          {{ record.ban.comment.length > 24 ? `${record.ban.comment.substr(0,24)}..` : record.ban.comment }}
                        </span>
                      </a>
                    </a-tooltip>
                  </span>
                </span>
              </div>
            </div>
          </span>
        </template>
        <template v-slot:payments="payment_sum, record">
          <span v-if="payment_sum && record.payment_counter.all > 0">
            <span>
              <a-tooltip title="Успешные платежи" placement="bottom">
                <a-badge status="success" />
                <span>
                  {{ payment_sum.confirmed_usd }} $ <sup>{{ record.payment_counter.confirmed }}</sup>
                </span>
              </a-tooltip>
            </span>
            <span class="d-block mt-1 text-gray-5">
              <a-tooltip title="Все платежи" placement="bottom">
                <a-badge color="grey" />
                <span>
                  {{ payment_sum.all_usd }} $ <sup>{{ record.payment_counter.all }}</sup>
                </span>
              </a-tooltip>
            </span>
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:created_at="created_at">
          <span v-if="created_at">
            {{ $moment(created_at).format('DD.MM.YY') }}
            <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment(created_at).format('HH:mm') }}</span>
          </span>
        </template>
<!--        <template v-slot:last_active="last_active">-->
<!--          <span v-if="last_active">-->
<!--            {{ $moment(last_active).format('DD.MM.YY') }}-->
<!--            <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment(last_active).format('HH:mm') }}</span>-->
<!--          </span>-->
<!--        </template>-->
        <template v-slot:operation="text, record">
          <div class="text-right">
            <span class="ml-3">
              <a-tooltip title="Заблокировать" placement="bottom" v-if="!record.ban">
                <a href="javascript:" class="font-size-18 text-danger" @click="showBanModal(record)">
                  <font-awesome-icon icon="ban" />
                </a>
              </a-tooltip>
              <a-tooltip title="Разблокировать" placement="bottom" v-else>
                <a-popconfirm
                  :title="`Разблокировать ${record.login}?`"
                  @confirm="unBanClient(record.id)"
                >
                  <a href="javascript:" class="font-weight-bolder font-size-18">
<!--                    <font-awesome-icon icon="door-open" class="text-success" />-->
                    <a-icon type="unlock" class="font-size-18" />
                  </a>
                </a-popconfirm>
              </a-tooltip>
            </span>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal v-model="banModal"
             :destroyOnClose="true"
             title=""
             :footer="null"
             class="ban-client-modal"
             width="480px"
    >
      <ban-client-modal
        :client-info="clientObj"
        @closeModalFunction="closeBanModal"
        @submitModalFunction="submitBanModal" />
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import apiClient from '@/services/axios'
import banClientModal from '@/views/clients/banClientModal.vue'
import moment from 'moment-timezone'
import ApiService from '@/services/api/api.service'

const invoiceColumns = [
  {
    title: 'Клиент',
    dataIndex: 'login',
    key: 'login',
    width: '48%',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Платежи',
    dataIndex: 'payment_sum',
    key: 'payment_sum',
    width: '20%',
    scopedSlots: { customRender: 'payments' },
  },
  {
    title: 'Зарегистрирован',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '17%',
    scopedSlots: { customRender: 'created_at' },
  },
  // {
  //   title: 'Был активен',
  //   dataIndex: 'last_active',
  //   key: 'last_active',
  //   width: '10%',
  //   scopedSlots: { customRender: 'last_active' },
  // },
  {
    title: 'Действие',
    dataIndex: 'operation',
    width: '15%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  components: {
    banClientModal,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['activeProjectList']),
  },
  data() {
    return {
      clientsData: [],
      banModal: false,
      clientObj: undefined,
      invoiceColumns,
      moment,
      clientLoading: false,
      clientsTableLoading: false,
      unbanLoading: false,
      pagination: { pageSize: 20 },
      searchField: 'login',
      searchValue: '',
      projectId: 0,
    }
  },
  mounted () {
    // this.getTransactions()
    this.refreshList()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
        project_id: this.projectId,
      })
    },
    handleProjectChange(e) {
      this.pagination.current = 1
      this.refreshList()
    },
    async fetch(params = {}) {
      console.log('params:', params)
      this.clientsTableLoading = true
      const reqData = { results: 20, ...params }
      console.log(reqData)
      const url = '/admin/clients'
      return await apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.clientsTableLoading = false
        this.clientsData = response.data.data
        this.pagination = pagination
        console.log(this.clientsData)
      }).catch(error => {
        console.log(error)
        this.clientsTableLoading = false
      })
    },
    async refreshList() {
      return this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        searchField: this.searchField,
        searchValue: this.searchValue,
        project_id: this.projectId,
      })
      // console.log(this.pagination)
    },
    async searchByInput(value) {
      // if (value === '') { this.searchValue =  }
      this.pagination.current = 1
      return this.refreshList()
    },
    showBanModal(client) {
      this.clientObj = client
      this.banModal = true
    },
    closeBanModal() {
      this.banModal = false
    },
    submitBanModal(client) {
      console.log(client.login + ' banned')
      const ind = this.clientsData.findIndex((obj) => obj.id === client.id)
      this.clientsData[ind].ban = client.ban
      this.banModal = false
    },
    async unBanClient(id) {
      this.unbanLoading = true
      return ApiService.unBanClient(id).then((response) => {
        this.$notification.success({
          message: 'Клиент разблокирован',
          description: '',
        })
        const ind = this.clientsData.findIndex((obj) => obj.id === id)
        this.clientsData[ind].ban = response.ban
        this.unbanLoading = false
      }).catch(error => {
        console.log(error)
        this.unbanLoading = false
      })
    },
    clipboardHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.$notification.success({
        message: 'Скопировано в буфер обмена',
        description: `${v.slice(0, 16)}...`,
      })
    },
  },
}
</script>

<style>
.client-status-dot .ant-badge-status-dot {
  width: 16px;
  height: 16px;
}
</style>
